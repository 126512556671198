import { setStorage, removeStorage, mergeDataToStorage } from "./StorageService";
import { sendPostRequest } from "../services/FetchService";
import * as FETCHAPI from "../constants/FetchApi";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import { getLanguagePrefix } from "../components/utilities/LanguageUtilities";

export const prepareSimulationData = (medicaQuestionnaire, paymentId, language) => {
  const languagePrefix = getLanguagePrefix(language);
  const {
    simulationName,
    startDate,
    endDate,
    numberOfStudents,
    templateName,
    discountCode,
    addVariation,
    addSuggestions,
  } = medicaQuestionnaire;
  const offset = -new Date().getTimezoneOffset() / 60;
  const postData = {
    simulationName: simulationName,
    maxNumberOfStudents: numberOfStudents,
    startTime: startDate,
    endDate: endDate,
    timeOffset: offset,
    transactionId: paymentId,
    discountCode: discountCode.discountCode,
    templateName: templateName,
    addVariation: addVariation,
    addSuggestions: addSuggestions,
    UserLanguage: languagePrefix,
  };
  return postData;
};

export const prepareSimulationDataWithManagedProgress = (medicaQuestionnaire, paymentId, language) => {
  const languagePrefix = getLanguagePrefix(language);
  const { simulationName, numberOfStudents, totalSimulatedDays, templateName, discountCode, addVariation } =
    medicaQuestionnaire;

  const postData = {
    simulationName: simulationName,
    maxNumberOfStudents: numberOfStudents,
    transactionId: paymentId,
    templateName: templateName,
    discountCode: discountCode.discountCode,
    totalStudentInteractionDays: totalSimulatedDays,
    addVariation: addVariation,
    UserLanguage: languagePrefix,
  };
  return postData;
};

export const handleStorage = (simulationId, activationLink, purchasedData) => {
  if (!purchasedData) {
    const data = {
      simulationId: simulationId,
      activationLink: activationLink,
    };
    removeStorage(LOCALSTORAGE.medicaQuestionnaire);
    setStorage(LOCALSTORAGE.simulationCreated, data);
  } else {
    mergeDataToStorage(LOCALSTORAGE.medicaQuestionnaire, purchasedData);
  }
};

export const validateDiscountCode = (discountCode) => {
  return sendPostRequest(FETCHAPI.MEDICA_VALIDATE_DISCOUNT_CODE, discountCode);
};

export const prepareCustomResponse = (discountCode, response, action) => {
  let customResponse = null;
  if (response.data.outcome === true && response.data.payload?.validDiscountCode === true) {
    customResponse = {
      type: `${action}_SUCCESSFUL`,
      payload: {
        discountCode: discountCode,
        discountedPrice: response.data.payload.discountedPrice,
      },
    };
  } else {
    customResponse = {
      type: `${action}_FAILED`,
      payload: {
        discountCodeMessage: response.data.message,
      },
    };
  }
  return customResponse;
};
