import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Container, Grid } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import ActionButtons from "../actionButtonComponent/ActionButtons";
import MobileActionButtons from "../actionButtonComponent/MobileActionButtons";
import MedicaDetailComponent from "./MedicaDetailComponent";
import ErrorModal from "../modals/ErrorModal";
import ShareSimulationModal from "../modals/ShareModal";
import ModifyModal from "../modals/ModifyModal";
import {
  getSimulationDetails,
  advanceSimulation,
  getDocuments,
  resetAdvanceSimulationProps,
} from "../../store/Medica/actions";
import "./SimulationDetailStyles.css";
import AdvanceSimulationModal from "../modals/AdvanceSimulationModal";
import DocumentsModal from "../modals/DocumentsModal";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";
import * as Sentry from "@sentry/react";
import { preventBack, allowBack, getUrlSearchParam } from "../../services/NavigationService";
import { filterActionButtons } from "./actionButtonBuilder";
import { GRAPHS_GROUP } from "../utilities/DashBoardUtilities";
import * as ButtonKeys from "./actionButtonConstants";

class MedicaDetailScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openErrorModal: false,
      openShareModal: false,
      openModifyModal: false,
      openAdvanceModal: false,
      openDocumentsModal: false,
      messageSuccess: true,
      simulationSetUpError: false,
      isWarning: false,
    };
  }

  componentDidMount() {
    const transaction = Sentry.startTransaction({
      name: "/MedicaDetail",
      op: "showMedicaDetail",
    });

    window.scrollTo(0, 0);
    const simulationIsCached = this.checkIfSimulationIsCached();

    if (this.props.location.pathname === ROUTES.PURCHASED_MEDICA_DETAIL) {
      preventBack();
    }

    if (!simulationIsCached) {
      const spanGetSimulationDetail = transaction.startChild({
        op: "getSimulationDetail",
        description: `Getting Simulation Detail`,
      });

      this.getSimulationDetails();

      spanGetSimulationDetail.finish();

      const spanGetDocumentation = transaction.startChild({
        op: "getDocumentation",
        description: `Getting Documentation`,
      });

      this.props.getDocuments(getUrlSearchParam());

      spanGetDocumentation.finish();
    }
    transaction.finish();
  }

  componentWillUnmount() {
    if (this.props.location.pathname === ROUTES.PURCHASED_MEDICA_DETAIL) {
      allowBack();
    }
  }

  checkIfSimulationIsCached = () => {
    const requestedSimulationId = getUrlSearchParam();
    const simulationSaved = this.props.medica.simulationDetails.simulationId;
    if (requestedSimulationId !== simulationSaved) {
      return false;
    }
    return true;
  };

  getSimulationDetails = () => {
    const { getSimulationDetails, language, t } = this.props;
    const translate = t;
    const requestedSimulationId = getUrlSearchParam();
    getSimulationDetails(requestedSimulationId, language, translate);
  };

  handleShareModal = () => {
    this.setState((state) => ({ openShareModal: !state.openShareModal }));
  };

  handleModifyModal = () => {
    this.setState((state) => ({ openModifyModal: !state.openModifyModal }));
  };

  handleErrorModal = () => {
    this.setState((state) => ({
      openErrorModal: !state.openErrorModal,
      isWarning: false,
    }));
  };

  handleAdvanceModal = () => {
    this.setState((state) => ({ openAdvanceModal: !state.openAdvanceModal }));
  };

  handleDocumentsModal = () => {
    this.setState((state) => ({ openDocumentsModal: !state.openDocumentsModal }));
  };

  registeredStudentsAction = () => {
    this.props.history.push({
      pathname: ROUTES.MEDICA_REGISTERED_STUDENTS,
      search: this.props.medica.simulationDetails?.data?.name,
    });
  };

  pushNotificationsAction = () => {
    this.props.history.push({
      pathname: ROUTES.PUSH_NOTIFICATIONS,
      search: this.props.medica.simulationDetails?.data?.name,
    });
  };

  standingsAction = () => {
    this.props.history.push({
      pathname: ROUTES.MEDICA_STANDINGS,
      search: this.props.medica.simulationDetails?.data?.name,
    });
  };

  decisionsHistoryAction = () => {
    const { language } = this.props.i18n;
    this.props.history.push({
      pathname: ROUTES.MEDICA_DETAILS_DECISION_HISTORY,
      search: `?simulationName=${this.props.medica.simulationDetails?.data?.name}&language=${language}`,
    });
  };

  dashBoardsAction = () => {
    this.props.history.push({
      pathname: ROUTES.MEDICA_DASHBOARDS,
      search: this.props.medica.simulationDetails?.data?.name,
      state: {
        selectedGraphs: GRAPHS_GROUP.PERFORMANCE,
      },
    });
  };

  onShowDocumentationClicked = () => {
    const requestedSimulationId = getUrlSearchParam();
    const { simulationDocumentation } = this.props;

    const documentationUpToDate = requestedSimulationId === simulationDocumentation.simulationId;
    if (!documentationUpToDate) {
      this.props.getDocuments(requestedSimulationId);
    }

    this.handleDocumentsModal();
  };

  onRunAgainClicked = () => {
    const { simulationDetails } = this.props.medica;
    const simulation = simulationDetails?.data;

    if (simulation.status === CONSTANT.INITIALIZED) {
      this.setState({
        errorTitle: "runAgainModalTitle",
        errorMessage: "runAgainModalMessage",
        isWarning: true,
        openErrorModal: true,
      });
      return;
    }

    this.props.history.push({
      pathname: ROUTES.RUN_AGAIN,
      state: { simulation: simulation },
    });
  };

  actionButtonsMapping = {
    [ButtonKeys.startSimulationKey]: {
      name: "AdvanceSimulationModal:startSimulation",
      action: this.handleAdvanceModal.bind(this),
    },
    [ButtonKeys.advanceSimulationKey]: {
      name: "AdvanceSimulationModal:advanceSimulation",
      action: this.handleAdvanceModal.bind(this),
    },
    [ButtonKeys.finishSimulationKey]: {
      name: "AdvanceSimulationModal:finishSimulation",
      action: this.handleAdvanceModal.bind(this),
    },
    [ButtonKeys.modifyKey]: {
      name: "modify",
      action: this.handleModifyModal.bind(this),
    },
    [ButtonKeys.standingsKey]: {
      name: "standings",
      action: this.standingsAction.bind(this),
    },
    [ButtonKeys.pushNotificationKey]: {
      name: "Common:pushNotification",
      action: this.pushNotificationsAction.bind(this),
    },
    [ButtonKeys.registeredStudentsKey]: {
      name: "registeredStudents",
      action: this.registeredStudentsAction.bind(this),
    },
    [ButtonKeys.shareSimulationKey]: {
      name: "shareSimulation",
      action: this.handleShareModal.bind(this),
    },
    [ButtonKeys.decisionsHistoryKey]: {
      name: "decisionHistory",
      action: this.decisionsHistoryAction.bind(this),
    },
    [ButtonKeys.dashboardsKey]: {
      name: "Common:dashboards",
      action: this.dashBoardsAction.bind(this),
    },
    [ButtonKeys.documentsKey]: {
      name: "documents",
      action: this.onShowDocumentationClicked.bind(this),
    },
    [ButtonKeys.runAgainKey]: {
      name: "runAgain",
      action: this.onRunAgainClicked.bind(this),
    },
    [ButtonKeys.backKey]: {
      name: "Common:back",
      action: () => this.props.history.push(ROUTES.HOME),
    },
  };

  render() {
    const translate = this.props.t;
    const { simulationDetails, loadingDetails, getSimulationDetailsError } = this.props.medica;
    const { simulationDocumentation } = this.props;
    const {
      openErrorModal,
      openShareModal,
      openAdvanceModal,
      openModifyModal,
      messageSuccess,
      openDocumentsModal,
      errorTitle,
      errorMessage,
      isWarning,
    } = this.state;
    let shareSimulationModal = null;
    let modifyModal = null;
    let advanceModal = null;
    let documentsModal = null;
    let errorModal = null;
    let actionButtons = null;
    let header = null;
    let mobileActionButtons = null;

    errorModal = (
      <ErrorModal
        handleErrorModal={this.handleErrorModal}
        translate={translate}
        warning={isWarning}
        openErrorModal={openErrorModal}
        title={translate(errorTitle)}
        message={translate(errorMessage)}
      />
    );

    if (getSimulationDetailsError !== "") {
      errorModal = (
        <ErrorModal
          handleErrorModal={this.handleErrorModal}
          translate={translate}
          openErrorModal={getSimulationDetailsError !== "" && !openErrorModal}
          message={translate(getSimulationDetailsError)}
        />
      );
    }

    if (!loadingDetails && simulationDetails.data) {
      shareSimulationModal = (
        <ShareSimulationModal
          translate={translate}
          open={openShareModal}
          shareLink={simulationDetails.medicaLink}
          titleModal={translate("ShareModal:title")}
          titleSecondaryButton={translate("ShareModal:cancel")}
          handleSecondaryButton={this.handleShareModal}
          messageType={messageSuccess}
        />
      );
      modifyModal = (
        <ModifyModal
          translate={translate}
          handleModifyModal={this.handleModifyModal}
          location={this.props.location}
          openModifyModal={openModifyModal}
          simulationDetails={simulationDetails.data}
          getSimulationDetails={this.getSimulationDetails}
          language={this.props.language}
        />
      );

      advanceModal = (
        <AdvanceSimulationModal
          open={openAdvanceModal}
          handleAdvanceModal={this.handleAdvanceModal}
          advanceSimulation={this.props.advanceSimulation}
          simulationDetails={simulationDetails.data}
          getSimulationDetails={this.getSimulationDetails}
          loading={this.props.advanceSimulationProps.loading}
          resetAdvanceSimulationProps={this.props.resetAdvanceSimulationProps}
          errorMessage={this.props.advanceSimulationProps.errorMessage}
          successMessage={this.props.advanceSimulationProps.successMessage}
        />
      );

      let actionButtonsList = filterActionButtons(simulationDetails.data, this.actionButtonsMapping);

      actionButtons = <ActionButtons translate={translate} actionButtons={actionButtonsList} />;
      mobileActionButtons = <MobileActionButtons translate={translate} actionButtons={actionButtonsList} />;
      header = <HeaderComponent translate={translate} dropdown={mobileActionButtons} />;
    } else {
      header = <HeaderComponent />;
    }

    documentsModal = (
      <DocumentsModal
        translate={translate}
        handleDocumentsModal={this.handleDocumentsModal}
        openDocumentsModal={openDocumentsModal}
        simulationDocumentation={simulationDocumentation}
      />
    );

    return (
      <Container>
        {errorModal}
        {advanceModal}
        {shareSimulationModal}
        {modifyModal}
        {documentsModal}
        <Grid>
          <Grid.Column mobile={16} computer={10} tablet={10}>
            {header}
            <MedicaDetailComponent
              translate={translate}
              location={this.props.location}
              simulationDetails={simulationDetails}
              loadingDetails={loadingDetails}
            />
          </Grid.Column>
          <Grid.Column only="mobile" mobile={16}>
            <div className="bottomArea">
              <Button className="goBackButton" onClick={() => this.props.history.push(ROUTES.HOME)}>
                {translate("Common:back").toLowerCase()}
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column className="rightColumn" only="tablet computer" computer={6} tablet={6}>
            {actionButtons}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSimulationDetails: (simulationId, language, translate) =>
    dispatch(getSimulationDetails(simulationId, language, translate)),
  getDocuments: (simulationName) => dispatch(getDocuments(simulationName)),
  advanceSimulation: (data) => dispatch(advanceSimulation(data)),
  resetAdvanceSimulationProps: () => dispatch(resetAdvanceSimulationProps()),
});

const mapStateToProps = (state) => {
  return {
    medica: {
      simulationDetails: state.medica.simulationDetails,
      loadingDetails: state.medica.loadingDetails,
      getSimulationDetailsError: state.medica.getSimulationDetailsError,
    },
    advanceSimulationProps: {
      successMessage: state.medica.advanceSimulationSuccessfully,
      loading: state.medica.advancingSimulation,
      errorMessage: state.medica.advanceSimulationError,
    },
    simulationDocumentation: {
      loading: state.medica.simulationDocumentation.loadingDocumentation,
      errorMessage: state.medica.simulationDocumentation.getDocumentationError,
      documentation: state.medica.simulationDocumentation.documentation,
    },
    language: state.user.userProfile.language,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("MedicaDetailScreen", "ModifyModal", "ShareModal", "MedicaAdvancedOptions", "Common"),
  withRouter
)(MedicaDetailScreen);
