const resources = {
  en: {
    AbogadosAdvanceSimulationModal: {
      AdvanceSimulationController_SimulationNotFound:
        "We couldn't find the simulation you are trying to advance. Please try again. If this happens again please contact us using the support chat.",
      AdvanceSimulationController_SimulationFinished: "The simulation you are trying to advance has already finished",
      AdvanceSimulationController_FailedAdvanceTeam:
        "We couldn't advance some teams. Please advance the simulation again to try to update the teams.",
      AdvanceSimulationController_WrongSimulation: "It seems that the simulation is used by another professor.",
      AdvanceSimulationController_SimulationHasNoTeams:
        "There are no students currently enrolled in this simulation. Please wait for your students to join before start the simulation.",
      AdvanceSimulationController_CouldNotSaveChanges:
        "Simulation data couldn't be saved. Please don't advance the simulation and contact us using the support chat.",
      AdvanceMessage: "Advance simulation one quarter.",
      StartSimulationMessage:
        "Please be aware that once you start this simulation, students will not be able to create new teams, they will only be able to join existing teams.",
      outdatedTeams: "Teams that failed to advance: ",
    },
    AdvanceSimulationModal: {
      advanceSimulation: "Advance Simulation",
      startSimulation: "Start Simulation",
      start: "Start",
      finishSimulation: "Finish Simulation",
      advance: "Advance",
      remainingDays: "Remaining student interaction period: ",
      currentDays: "Current Day",
      daysToAdvance: "Days to Advance",
      futureDays: "Future Day",
      warningFinishMessage:
        'This is the last chance your students have to make changes within the simulation. Once you press the "Finish" button, 50 virtual days will elapse and the simulation will end.',
      currentYear: "Current Year",
      yearsToAdvance: "Years to Advance",
      resultingYear: "Resulting Year",
      "Simulation advanced successfully.": "Simulation advanced successfully.",
      simulationFinishedSuccessfully: "Simulation finished successfully.",
      "Error. Simulation can only be advanced after at least one team has been created.":
        "The simulation can only be advanced after at least one team has been created.",
      "Please use a non negative amount of days to simulate": "Please use a non negative amount of days to simulate.",
      "There has been an authentication error, please contact us at support@processimlabs.com":
        "There has been an authentication error, please contact us at support@processimlabs.com to get assistance.",
      "Your account is setup as a student's account. please contact us at support@processimlabs.com":
        "Your account is setup as a student's account. please contact us at support@processimlabs.com to get assistance.",
      "You don't seem to own this simulation. Please contact us at support@processimlabs.com.":
        "You don't seem to own this simulation. Please contact us at support@processimlabs.com to get assistance.",
      "Couldn't find control team":
        "Couldn't find control team. Please contact us at support@processimlabs.com to get assistance.",
      "This simulation advances on its own": "This simulation advances on its own.",
      "Simulation is not running.": "Simulation is not running.",
      "Nothing to run": "Nothing to run.",
      "There was an error advancing please try again.": "There was an error advancing please try again.",
      "Other operation running, try again.":
        "We weren't able to advance the simulation at the moment. Please advise your students to stop making changes in the simulation temporarily and try again. Thank you for your patience.",
      days: "days.",
      errorAdvancingSimulation:
        "An error has occurred advancing your simulation, please check your Internet connection and reload the page.",
      advanceAgain: "Advance again",
      errorSimultaneousChangeRequest: `Uh oh! It seems like one of your students was trying to change something in the simulation when you tried to advance it. Please let them know you are about to try again and do so.`,
      BackendSimulation_SimulationLimitError:
        "The simulation has finished. We hope you enjoyed the experience. Thanks for participating!",
    },
    AuthComponents: {
      loadingProfile: "Loading profile, please wait...",
      loadingPage: "Loading page, please wait...",
    },
    CatalogueScreen: {
      catalogue: "Catalogue",
      getCreative:
        "Check out our simulation catalog. Don't hesitate to reach out if you'd like to learn more about any of them.",
      select: "Select",
      back: "Back",
      medicaFlavor:
        "Take the role of the manager of a medical device manufacturing facility with two different production lines. Look after its operations, buy machinery, hire employees, price your products, order inventory, etc.",
      medicaTopics: "Operations mgmt, Inventory mgmt, Capacity, and more.",
      simunationFlavor:
        "Learn about the importance of sustainable growth and informed decision-making while ruling over various sectors of a virtual country like Education, Production, Water, Health, Finances, etc.",
      simunationTopics: "Social progress, Sustainable development, Economics, and more.",
      bindzFlavor:
        "Manage the supply chain of an adhesives manufacturing company while facing various crises affecting its operations depending on the decisions made during the simulation.",
      bindzTopics: "Supply chain mgmt, Crisis mgmt, and more.",
      supplyGrainFlavor:
        "You are in charge of the supply chain for a company that produces cereals. Make decisions regarding each one of the segment’s operations and the logistics related to the delivery of the produced goods to end customers.",
      supplyGrainTopics: "Supply chain mgmt, Logistics, Distribution network design, and more.",
      abogadosFlavor:
        "Students are in control of a prestigious and established legal firm with different tiers of employees, that can serve various market segments.",
      abogadosTopics:
        "Service Operations, Human Capital, Organizational Structure, Service Capacity Planning and more.",
      creambowFlavor:
        "Be the manager of an ice cream factory. Make vital decisions regarding the flavors, sizes, target markets and production itself of various delicious treats.",
      creambowTopics:
        "Business Strategy, Competitive Priorities, Operations Strategy, Capacity Planning, Process Analysis, and more.",
      topic: "Topics: {{topicsList}}",
    },
    CreambowAdvanceSimulationModal: {
      AdvanceSimulationController_SimulationNotFound:
        "We couldn't find the simulation you are trying to advance. Please try again. If this happens again please contact us using the support chat.",
      AdvanceSimulationController_SimulationFinished: "The simulation you are trying to advance has already finished",
      AdvanceSimulationController_FailedAdvanceTeam:
        "We couldn't advance some teams. Please advance the simulation again to try to update the teams.",
      AdvanceSimulationController_WrongSimulation: "It seems that the simulation is used by another professor.",
      AdvanceSimulationController_SimulationHasNoTeams:
        "There are no students currently enrolled in this simulation. Please wait for your students to join before start the simulation.",
      AdvanceSimulationController_CouldNotSaveChanges:
        "Simulation data couldn't be saved. Please don't advance the simulation and contact us using the support chat.",
      AdvanceMessage: "Advance the simulation a fortnight",
      outdatedTeams: "Teams that failed to advance: ",
    },
    CreateNewScenarioScreen: {
      createNewScenario: "Create New Scenario",
      hasVariance: "Has variance?",
      name: "Name",
      description: "Description",
      seed: "Seed",
      initialCash: "Initial Cash",
      stationOneMachineCapacity: "Station One Machine Capacity",
      stationTwoMachineCapacity: "Station Two Machine Capacity",
      stationThreeMachineCapacity: "Station Three Machine Capacity",
      inventoryLeadTime: "Inventory Lead Time",
      batchSetupTime: '"Initial Batching" Setup Time',
      customLineWipLimit: "Custom Line WIP Limit",
      customDemandInitialValue: "Custom Demand Initial Value",
      customDemandFinalValue: "Custom Demand Final Value",
      customDemandStabilizationDay: "Custom Demand Stabilization Day",
      customDemand: "Custom Demand",
      customDemandHasVariance: "Custom Demand has variance?",
      required: "Required field",
      stationOneMachineHasVariance: "Station One Machine Capacity has variance?",
      stationTwoMachineHasVariance: "Station Two Machine Capacity has variance?",
      stationThreeMachineHasVariance: "Station Three Machine Capacity has variance?",
      areYouSureDoYouWantCreateNewScenario:
        "Are you sure do you want create a new scenario with the following information?",
      newScenarioConfirmation: "New Scenario Confirmation",
      create: "Create",
      scenarioHasBeenCreatedSuccessfully: "Your scenario has been created successfully.",
      errorCreatingNewScenario:
        "An error has occurred creating your scenario, please check your Internet connection and reload the page.",
      scenarioNameAlreadyExist: "Scenario name already exist",
      "Initial Cash should be between 50K and 250K": "Initial Cash should be between 50K and 250K.",
      "Seed should be between 0 and 20000": "Seed should be between 0 and 20000.",
      "Station 1 Machine Capacity should be between 45 and 90":
        "Station 1 Machine Capacity should be between 45 and 90.",
      "Station 2 Machine Capacity should be between 90 and 180":
        "Station 2 Machine Capacity should be between 90 and 180.",
      "Station 3 Machine Capacity should be between 120 and 240":
        "Station 3 Machine Capacity should be between 120 and 240.",
      "Inventory Lead Time should be between 3 and 8": "Inventory Lead Time should be between 3 and 8.",
      "Batch Setup Time should be between 3 and 8": "Batch Setup Time should be between 3 and 8.",
      "Custom Line Wip Limit should be between 600 and 1200": "Custom Line WIP Limit should be between 600 and 1200.",
      "Custom Demand Initial Value should be between 0 and 50":
        "Custom Demand Initial Value should be between 0 and 50.",
      "Custom Demand Final Value should be between 1 and 400": "Custom Demand Final Value should be between 1 and 400.",
      "Custom Demand Stabilization Day should be between 100 and 300":
        "Custom Demand Stabilization Day should be between 100 and 300.",
      "The scenario already exists": "The scenario already exists.",
      "units/day": "units/day",
      days: "days",
      units: "units",
      orders: "orders",
      UnitInterpolation: "{{count}} unit",
      UnitInterpolation_plural: "{{count}} units",
      DayInterpolation: "{{count}} day",
      DayInterpolation_plural: "{{count}} days",
      OrderInterpolation: "{{count}} order",
      OrderInterpolation_plural: "{{count}} orders",
      DollarsInterpolation: "$ {{count}}",
      UnitPerDayInterpolation: "{{count}} unit/day",
      UnitPerDayInterpolation_plural: "{{count}} units/day",
      randomize: "Randomize",
      seedMustBeBetweenZeroAndTwentyThousand: "This value must be between 0 and 20,000.",
      goToList: "Go to your scenario list",
      initialCashHelp: "Amount available at the beginning of the simulation.",
      stationOneMachineCapacityHelp: "Amount of units produced per day by each machine in this station.",
      stationTwoMachineCapacityHelp: "Amount of units produced per day by each machine in this station.",
      stationThreeMachineCapacityHelp: "Amount of units produced per day by each machine in this station.",
      inventoryLeadTimeHelp: "Amount of days that takes for each order of raw material inventory to arrive.",
      batchSetupTimeHelp: "Amount of days that takes for each batch to be processed at Initial Batching.",
      customLineWipLimitHelp: "Maximum amount of units that can be processed by the Custom Line at any time.",
      customDemandInitialValueHelp: "Starting value for Custom Line daily demand.",
      customDemandFinalValueHelp: "Final value for Custom Line daily demand.",
      customDemandStabilizationDayHelp:
        "Virtual day at which Custom Line's demand stabilizes at the Custom Demand Final Value.",
      seedValueHelp:
        "This value is used to calculate random values of variance, different seed values will result in slightly different scenarios.",
    },
    CrisisAdvancedOptions: {
      selectYourScenario: "Select your scenario: ",
      defaultScenarioTitle: "Default Scenario",
      defaultScenarioDescription: "This scenario is recommended for first time users.",
    },
    CrisisAdvanceSimulationModal: {
      StartSimulationController_SimulationEmpty:
        "There are no students currently enrolled in this simulation. Please wait for your students to join before starting the simulation.",
      StartSimulationController_MoreTeamThanStudents:
        "Please pick a number of teams that is less than the number of registered students.",
      StartSimulationController_InsufficientTeams:
        "Please pick a greater number of teams. The maximum number of students in the same team should be 4 or less",
      StartSimulationController_FailedStartTeam:
        "The process of starting the simulation has failed. Please try again. If this happens again please contact us using the support chat.",
      StartSimulationController_SimulationNotFound:
        "We couldn't find the simulation you are trying to start. Please try again. If this happens again please contact us using the support chat.",
      AdvanceSimulationController_SimulatinNotFound:
        "We couldn't find the simulation you are trying to advance. Please try again. If this happens again please contact us using the support chat.",
      AdvanceSimulationController_SimulationFinished: "The simulation you are trying to advance has already finished",
      AdvanceSimulationController_FailedAdvanceSimulation:
        "We couldn't advance the simulation. Please try again in a couple of minutes.",
      NumberOfTeams: "Number of teams",
      NumberOfWeeks: "Number of weeks",
      AdvanceSimulationController_SimulationDoesntBelong: "It seems that the simulation is used by another professor.",
      AdvanceSimulationController_StartSimulation: "The simulation hasn't started yet. Please start simulation first.",
      StudentsAreJoined: "Please make sure all your students have joined the simulation before starting.",
      RegisteredStudents: "Registered students:",
      RecommendedNumberOfTeams: "Recommended number of teams:",
      RoundedUp: "N/4 rounded up",
      MissingSpotsMessage: "Missing spots will be filled by bots",
    },
    CrisisBadLink: {
      badLink: "It seems that you may have a broken link.",
      tryFollowing:
        "Please double-check with your professor that you have the correct link. If that's the case, please let us know at <1>support@processimlabs.com</1> or by using the chat on the bottom right corner of this page.",
    },
    CrisisBuySimulation: {
      creatingSimulation: "Creating Simulation...",
      numberOfStudentsError: "The number of students should be at least 4.",
      numberOfStudents: "Number of students",
      whoPaysSelected: "Who will be paying?",
      pleaseChooseOne: "Please choose one",
      whoPaysHelp:
        "The cost of the licenses can be covered by the institution or by each individual student when signing up",
      verifyFieldsCorrectlyFilled: "Please verify that all fields are correctly filled.",
      CreateSimulationController_MissingPayment: "We couldn't verify your payment, please contact us using the chat.",
      SimulationNameInUse:
        "Oops, that simulation name has already been taken, we suggest using something more specific",
      SimulationNameTooLong: "Simulation's name is too long, please try with a shorter name of maximum 25 characters.",
    },
    CrisisDetailScreen: {
      createNextRun: "Create Next Run",
      GetSimulationDetailsController_CantUseAPI: "You don't have permission to use this API, please contact support.",
      GetSimulationDetailsController_SimulatinNotFound:
        "We couldn't find the simulation. Please try again. If this happens again please contact us using the support chat.",
    },
    CrisisJoinSimulation: {
      joinSimulationSuccessful: "You have successfully joined the simulation",
      JoinSimulationController_SimulationNotFound:
        "Oops, it appears that the simulation you are trying to join does not exist, please contact your professor and make sure you are using the correct link.",
      failedJoinSimulation: "Failed to join Bindz simulation.",
      JoinSimulationController_AlreadyJoined:
        "You already joined this simulation, please download Bindz from your phone's store and login.",
      JoinSimulationController_MissingPaymentSummary:
        "It seems that the simulation you try to join has not been paid, please verify the payment with your professor",
      JoinSimulationController_ExcededLicenses:
        "Oops, there is no paid license available for you to join the simulation, please verify the payment with your professor",
    },
    CrisisCommon: {
      OneStationUnknownPeers: "One station - unknown peers",
      OneStationKnownPeers: "One station - known peers",
      AllStationsKnownPeers: "All stations",
      GameModeHelp: "Game mode lets you can select how your students will interact the simulation and with each other.",
      OneStationUnknownPeersHelp:
        "One Section - Unknown Peers: each student will manage only one section of the supply chain but they will not know who are their partners managing the other sections.",
      OneStationKnownPeersHelp:
        "One Section - Known Peers: each student will manage only one section of the supply chain and will also know what team they belong to.",
      AllStationsKnownPeersHelp:
        "All Sections - Known Peers: each student has access to all of the sections of the supply chain and will also know what team they belong to.",
      SimulationPerformanceCatalogue_SupplyAndAssemblyRawMaterials: "Supply And Assembly Raw Materials",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductA: "Supply And Assembly Product A",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductB: "Supply And Assembly Product B",
      SimulationPerformanceCatalogue_ProductionOneProductA: "Production One Product A",
      SimulationPerformanceCatalogue_ProductionOneProductB: "Production One Product B",
      SimulationPerformanceCatalogue_ProductionTwoProductA: "Production Two Product A",
      SimulationPerformanceCatalogue_ProductionTwoProductB: "Production Two Product B",
      SimulationPerformanceCatalogue_RetailerOnlineProductA: "Retailer Online Product A",
      SimulationPerformanceCatalogue_RetailerOnlineProductB: "Retailer Online Product B",
      SimulationPerformanceCatalogue_RetailerOfflineProductA: "Retailer Offline Product A",
      SimulationPerformanceCatalogue_RetailerOfflineProductB: "Retailer Offline Product B",
      SimulationPerformanceCatalogue_Finance: "Finances",
      SimulationPerformanceCatalogue_MaterialX: "Material X Level",
      SimulationPerformanceCatalogue_MaterialY: "Material Y Level",
      SimulationPerformanceCatalogue_Production: "Production",
      SimulationPerformanceCatalogue_FinishedGoods: "Finished Goods",
      SimulationPerformanceCatalogue_IncomingInventory: "Incoming Inventory",
      SimulationPerformanceCatalogue_StationOne: "Station 1",
      SimulationPerformanceCatalogue_ManualStation: "Manual Station",
      SimulationPerformanceCatalogue_LaborUtilization: "Labor Utilization",
      SimulationPerformanceCatalogue_Deliveries: "Deliveries",
      SimulationPerformanceCatalogue_Demand: "Demand",
      SimulationPerformanceCatalogue_SupplyAndAssembly: "Supply And Assembly",
      SimulationPerformanceCatalogue_ProductionOne: "Production 1",
      SimulationPerformanceCatalogue_ProductionTwo: "Production 2",
      SimulationPerformanceCatalogue_Retailer: "Retailer",
    },
    CrisisDashboards: {
      GetSimulationPerformanceController_SimulationShouldStarted:
        "Charts with information about the teams in this simulation will be available here after you start the simulation.",
    },
    CrisisModifyModal: {
      ModifySimulation: "Modify Simulation",
      cancel: "Cancel",
      accept: "Apply",
      success: "The simulation has been successfully modified",
    },
    CrisisPurchased: {
      GameModeTitle: "Select a game mode",
      SimulationStartInTheFuture: "Simulation needs to start at least in half an hour",
      SimulationMinDurationError: "Simulation needs to last longer than 6 hours",
      SimulationMaxDurationError: "Simulation maximum duration is 52 hours",
      AdvancedOptions: "Advanced Options",
    },
    CrisisShareModal: {
      ShareJoinLInk: "Share this link with your students for them to join your simulation.",
    },
    CrisisStandings: {
      standingsTitle: "Standings",
      Team: "Team",
      Total: "Total Cash",
      SupplyAndAssembly: "Supply & Assembly",
      ProductionOne: "Production 1",
      ProductionTwo: "Production 2",
      Retail: "Retail",
      EmptyStandings:
        "We are sorry, we couldn't find any standings for your simulation. You should see standings after starting your simulation. Please come back later.",
      All: "All",
      "Team Member": "Team Member",
      Email: "Email",
      Role: "Role",
      Position: "Position",
    },
    CrisisRunAgain: {
      CreateNextRunController_SimulationNotFound:
        "There was an error setting up your simulation, please try again, if that doesn't work please contact us using the chat bubble or via email at support@processimlabs.com.",
      CreateNextRunController_ErrorInitializingSimulation:
        "There was an error initializing your simulation, please try again, if that doesn't work please contact us using the chat bubble or via email at support@processimlabs.com.",
      scrambleTeamMembers: "Change team members: ",
      createNextRunSuccess:
        "Your simulation has been set up correctly. You will be able to access the new simulation from the home page.",
      Success: "Success!",
    },
    CookiesDisabledScreen: {
      cookiesNotEnabled: "Cookies seem to be disabled in your browser. Please enable them or use a different browser.",
    },
    Common: {
      logout: "Logout",
      simulation: "Simulation",
      name: "Name",
      endDate: "End Date",
      status: "Status",
      back: "Back",
      previous: "Previous",
      pushNotification: "Push Notifications",
      team: "Team",
      showing: "Showing",
      to: "to",
      of: "of",
      continue: "Continue",
      notValidNumber: "It must be a number",
      yes: "Yes",
      no: "No",
      next: "Next",
      startDate: "Start date",
      students: "students",
      Students: "Students",
      institution: "Institution",
      welcome: "Welcome",
      Professor: "Professor",
      Student: "Student",
      accept: "Accept",
      cancel: "Cancel",
      today: "today",
      close: "Close",
      error: "Error",
      advanceSimulation: "Advance Simulation",
      home: "Home",
      confirmation: "Confirmation",
      currentDay: "Current Simulation Day",
      dashboards: "Dashboards",
      finish: "Finish",
      notice: "Notice",
      success: "Success",
      teams: "Teams",
      documents: "Documents",
      warning: "Warning",
      setLanguage: "Set your language",
      Initialized: "Initialized",
      AwaitingInitialization: "Ready to Start",
      Running: "Running",
      Finished: "Finished",
      profile: "Profile",
      Spanish: "Spanish",
      English: "English",
      tryAgain: "Try again",
      unknownBackendErrorMessage:
        "Wow, that didn't go as expected, please try it again. If you see this message again please contact us using the chat.",
      unknownBackendExceptionMessage:
        "Wow, that didn't go as expected, please try it again. If you see this message again please contact us using the chat.",
      scenarioManager: "Scenario Manager",
      "Busy Server": "Oh no, the server is busy at the moment, please try again.",
    },
    DiscountCodeModal: {
      discountCode: "Discount Code",
      yourCode: "Your discount code...",
      validate: "Validate code",
      "No matching discount code found.": "No matching discount code found.",
      discountSuccess: "Your discount code has been validated successfully.",
      errorValidatingDiscountCode:
        "An error has occurred while validating your discount code, please check your Internet connection and reload the page.",
      "This discount code already expired.": "This discount code has already expired.",
      "You exceeded the number of licenses you can create with your discount code.":
        "You've exceeded the number of licenses you can create with your discount code.",
      "Discount code not found.": "This discount code is meant to be used with a different email.",
      ValidateDiscountCodeController_DiscountCodeNotFound: "No matching discount code found.",
      ValidateDiscountCodeController_DiscountCodeExpired: "This discount code has already expired.",
      ValidateDiscountCodeController_ExceededNumberOfLicenses:
        "You've exceeded the number of licenses you can create with your discount code.",
      ValidateDiscountCodeController_DiscountCodeDoesntBelong:
        "This discount code is meant to be used with a different user.",
      ValidateDiscountCodeController_EmptySimulationId:
        "Oops, the simulation was not included in the discount code validation, please try again. If this happens again please contact us using the support chat.",
      ValidateDiscountCodeController_PaymentSummaryNotFound:
        "Oops, it seems that the simulation has not created correctly. Verify with your professor that he created simulation.",
    },
    DashBoardsScreen: {
      loadingTeamData: "Loading teams data, please wait...",
      teamAlreadySelected: "You have already selected this team.",
      errorGettingPerformance:
        "An error has occurred while fetching your teams performances, please check your Internet connection and reload the page.",
      errorGettingDecisions:
        "An error has occurred while fetching your teams decisions, please check your Internet connection and reload the page.",
      manageTeams: "Manage Teams",
      refresh: "Refresh",
      chooseATeam: "Choose teams",
      selectAll: "Select all",
      unselectAll: "Unselect all",
      noTeamsSelected: "You have no team selected.",
      loadingGraphMarkers: "Drawing the graph points, please wait...",
      General: "General",
      Finances: "Finances",
      "Standard Line": "Standard Line",
      "Custom Line": "Custom Line",
      "Work Force": "Work Force",
      "Cash On Hand": "Cash On Hand",
      Inventory: "Inventory",
      "Standard Market Price": "Standard Market Price",
      "Custom Demand": "Custom Demand",
      Cash: "Cash",
      Debt: "Debt",
      "Sales Standard": "Sales Standard",
      "Sales Custom": "Sales Custom",
      "Accepted Orders": "Accepted Orders",
      "Accumulated Orders": "Accumulated Orders",
      "Queue 1": "Queue 1",
      "Station 1 Output": "Station 1 Output",
      "Queue 2": "Queue 2",
      "Initial Batching": "Initial Batching",
      "Queue 3": "Queue 3",
      "Manual Processing": "Manual Processing",
      "Queue 4": "Queue 4",
      "Final Batching": "Final Batching",
      "Queue 5": "Queue 5",
      "Inventory Costs": "Inventory Costs",
      "Salary Costs": "Salary Costs",
      "Holding Costs": "Holding Costs",
      Deliveries: "Deliveries",
      "Queue 2 First Pass": "Queue 2 First Pass",
      "Station 2 Output": "Station 2 Output",
      "Station 3 Output": "Station 3 Output",
      "Queue 2 Second Pass": "Queue 2 Second Pass",
      "Actual Price": "Actual Price",
      "Average Lead Time": "Average Lead Time",
      Rookies: "Rookies",
      Experts: "Experts",
      days: "Days",
      "Amount ($)": "Amount ($)",
      "Raw Material": "Raw Material",
      Units: "Units",
      Workers: "Workers",
      day: "Day",
      value: "Value",
      "There was a problem formatting the data. Please try again or contact support at: support@processimlabs.com":
        "There was a problem formatting the data. Please try again or contact support at: support@processimlabs.com to get assistance.",
      "No matching simulations found": "No matching simulations found.",
      "There was an error creating the report. Please try again or contact support at: support@processimlabs.com":
        "There was an error creating the report. Please try again or contact support at: support@processimlabs.com to get assistance.",
      topThree: "Top 3",
      topFive: "Top 5",
      topTen: "Top 10",
      graphs: "Graphs",
      performance: "Performance",
      decisions: "Decisions",
      "Standard Order Frequency": "Order Frequency",
      "Standard Order Size": "Order Size",
      "Standard Station One Buy": "Station 1 Number of Machines",
      "Standard Station One Capacity Allocation": "Station 1 Capacity Allocation to Standard Line",
      "Standard Initial Batch Size": "Initial Batch Size",
      "Standard Manual Work Day in Shifts": "Manual Work Day in Shifts",
      "Standard Final Batch Size": "Final Batch Size",
      "Standard Product Price": "Product Price",
      "Custom Station Two Buy": "Station 2 Number of Machines",
      "Custom Station Two Capacity Allocation": "Station 2 Capacity Allocation to First Pass",
      "Custom Station Three Buy": "Station 3 Number of Machines",
      "Reorder Point": "Reorder Point",
      "Reorder Quantity": "Reorder Quantity",
      "Get Loan": "Get Loan",
      "Pay Loan": "Pay Loan",
      "Desired Number Of Employees": "Desired Number Of Employees",
      "Simulation not owned by this user": "Simulation not owned by this user.",
      noTeamsToDisplayInformation: "This simulation doesn't have teams to show decisions graphs yet.",
      loadingDecisionsData: "Loading decisions data.",
      noTeamSelectedToDisplayInformation: "Please select a team from the list on the left.",
    },
    DecisionHistory: {
      DecisionHistoryTitle: "Decision History",
      loadingDecisionHistory: "Loading decision history...",
      userName: "Name",
      description: "Description",
      timeSubmitted: "Time submitted",
      simulationDay: "Simulation day",
      noChangesYet: "This team has not made any changes yet.",
    },
    DownloadApp: {
      youAreGoodToGo: "You are good to go!",
      pleaseDownloadOurMobileApp:
        "Please download our mobile app from the corresponding store by clicking on one of the buttons below:",
      sameCredentials:
        "Log in to the mobile app using the same username and password you use for our website and start managing your own virtual company.",
      userPlayedBefore: `If you already have Medica Scientific installed in your phone and have played a simulation before, in the app go to Settings and tap on "Switch Simulation" to start playing this new one.`,
      orClickHereToDownloadItToYourWindowsComputer: "Or click <1>here</1> to download it to your Windows computer.",
    },
    FAQ: {
      title: "Frequently Asked Questions",
      All: "All",
      "Create Simulation": "Create Simulation",
      "Simulation Details": "Simulation Details",
      "Student FAQ": "Student FAQ",
      "Advance Simulation": "Advance Simulation",
      Login: "Login",
      "Create Account": "Create Account",
      "Join Team": "Join Team",
      Payment: "Payment",
      Gameplay: "Gameplay",
      "Standard Line": "Standard Line",
      "Custom Line": "Custom Line",
      Inventory: "Inventory",
      "Work Force": "Work Force",
      "Activation Code": "Activation Code",
      "Join Simulation": "Join Simulation",
      "Supply and Assembly": "Supply and Assembly",
      "Production 1": "Production 1",
      "Production 2": "Production 2",
      "Create Team": "Create Team",
    },
    HomeScreen: {
      students: "Students",
      addNewSimulation: "Create New Simulation",
      startDate: "Start Date",
      simulations: "Simulations",
      errorFetchingSimulations:
        "An error has occurred while fetching your simulations, please check your Internet connection and reload the page.",
      loadingSimulations: "Loading Simulations, please wait...",
      "User doesn't have any simulations": "You don't have any simulations.",
      professorManaged: "Professor Managed",
      pending: "Pending Start",
      noSimulationList:
        "In this section, you will see your simulations. When you are ready, click the button below to create your first simulation.",
      "Simulation not found": "You don't have any simulations.",
      at: "at",
      welcomeToProcessimlabs: "Welcome to Processim Labs!",
      WelcomeModalGoToVideos: "Go to Videos",
      WelcomeModalTitle: "Welcome to your Processim Labs account!",
      WelcomeModalBody1:
        "This is your home screen, where you will find all of the simulations you have created, and access and monitor them when needed.",
      WelcomeModalBody2:
        "We invite you to check out our Videos section. There you will find several videos that can help you navigate around our website, set up our simulation games and more.",
      WelcomeModalNeverShowAgain: "Never Show Again",
    },
    JoinTeamModal: {
      ok: "Ok",
      retry: "Retry",
      cancel: "Cancel",
    },
    MedicaDetailScreen: {
      simulationDetails: "Simulation Details",
      initializationPeriod: "Initialization Period",
      studentInteractionPeriod: "Student Interaction Period",
      endingPeriod: "Ending Period",
      activationCode: "Activation Code",
      startTime: "Start Date",
      templateName: "Selected Scenario",
      modify: "Modify",
      standings: "Standings",
      registeredStudents: "Registered Students",
      shareSimulation: "Share Simulation",
      runAgain: "Run Again",
      decisionHistory: "Decisions History",
      errorFetchDetails:
        "An error has occurred while fetching the information, please check your Internet connection and reload the page.",
      virtualDaysPerHour: "Virtual Days per Hour",
      medicaVirtualDaysHelp:
        "This is the amount of virtual days the simulation will advance per real world hour during the student interaction period.",
      virtualDays: "virtual days",
      medicaStatusHelp:
        "After a simulation has been created, it will have one these statuses: Initialized, Running or Finished.",
      medicaInitializationPeriodHelp:
        "Amount of virtual days that have already elapsed before the student interaction period.",
      medicaStudentInteractionPeriodHelp: "Amount of virtual days that the students will have to manage the company.",
      medicaEndOfSimulationPeriodHelp: "Amount of days that will run after the interaction period is over.",
      professorManaged: "Professor Managed",
      downloadAll: "Download all",
      runAgainModalTitle: "Simulation Set Up",
      runAgainModalMessage:
        "Before you can set up your next run your current simulation must be running or have already finished.",
      documentationCurrentlyBeingCreated:
        "Simulation documentation is currently being created. Please check back shortly.",
      errorFetchingDocuments:
        "An error has occurred while fetching the simulation documents, please check your Internet connection and reload the page.",
      "Please create a simulation before getting documentation.":
        "Please create a simulation before getting documentation.",
      "User doesn't own a simulation with that name": "User doesn't own a simulation with that name.",
      "User doesn't own any simulations": "User doesn't own any simulations.",
      "User doesn't own this simulation": "User doesn't own this simulation.",
      documents: "Documents",
      at: "at",
    },
    MedicaBuyQuestionnaire: {
      whoPaysHelp:
        "The cost of the licenses can be covered by the institution or by each individual student when signing up",
    },
    MedicaBadLink: {
      badLink: "It seems that you may have a broken link.",
      tryFollowing: "Please try one of the following:",
      tryingToCreateTeam:
        "If you are trying to create a team, double-check with your professor that you have the correct link.",
      tryingToJoinTeam: "If you are trying to join a team, check with your team leader instead.",
      lastResort:
        "If this doesn't work please reach us at <1>support@processimlabs.com</1> or using the chat on the bottom right corner of this page.",
    },
    ModifyModal: {
      modify: "Modify",
      accept: "Accept",
      cancel: "Cancel",
      name: "Name",
      startDate: "Start date",
      endDate: "End date",
      modifyDatesSuccess: "Dates changed successfully.",
      equalDatesSimulationError: "Simulation has the same selected start and end dates.",
      "End Date must be after Start Date": "End Date must be after Start Date.",
      "Dates must be in the future": "Dates must be in the future.",
      "Simulation must run for at least 6 hours": "Simulation must run for at least 6 hours.",
      "Cannot change Start Time one hour before simulation starts":
        "Cannot change start time one hour before simulation starts.",
      errorPosting:
        "An error has occurred while sending the request, please check your Internet connection and try again.",
      "Simulation can only run for 7 days or less": "Simulation can only run for 7 days or less",
      "Bad request":
        "An error has occurred while sending the request please check try again or contact us at: support@processimlabs.com to get assistance.",
      "Bad Date format":
        "There was an error submitting the request. Please try again or contact us at: support@processimlabs.com for help.",
      "Couldn't find control team":
        "Couldn't find control team. Please try again or contact us at: support@processimlabs.com to get assistance.",
      "Couldn't find simulation Activation Code":
        "Couldn't find simulation Activation Code. Please try again or contact us at: support@processimlabs.com to get assistance.",
      simulationMustStartLeastOneHourInFuture: "The simulation must start at least one hour in the future.",
    },
    Payment: {
      payment: "Checkout",
      wouldYouLikeToCompleteThePurchase: "Would you like to complete the purchase?",
      purchaseComplete: "Purchase Complete",
      creditCardInformation: "Credit card information",
      cardHolderName: "Cardholder name",
      required: "Required field",
      yourPaymentHasBeenSuccessfullyRegistered: "Your payment has been successfully registered",
      paymentSuccess: "Payment Success",
      purchasedCompleted: "Purchased Completed",
      pay: "Pay",
      discountCode: "Discount Code",
      removeDiscountCode: "Remove Discount Code",
      cancel: "Cancel",
      accept: "Accept",
      writeValidCode: "Write a valid code",
      checkingStudentPayment: "Checking for previous payment",
      studentAlreadyPayedMessage: "Your payment has been verified",
      checkPreviousPaymentError: "There was an error checking for payment",
      simulationAlreadyFinished:
        "This simulation has already finished. Please check with your professor that your link is correct.",
      "You've already payed for this simulation": "You've already payed for this simulation.",
      errorPostingPayment: "There was an error handling your payment please try again or use a different card.",
      expired_card: "The card has expired. Please try another card.",
      fraudulent: "Your card was declined. Please contact your card issuer for more information.",
      generic_decline: "Your card was declined. Please contact your card issuer for more information.",
      card_declined: "Your card was declined. Please contact your card issuer for more information.",
      insufficient_funds: "Your card has insufficient funds.",
      transaction_not_allowed:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      incorrect_cvc: "The CVC number is incorrect. Please check your card information.",
      invalid_cvc: "The CVC number is incorrect. Please check your card information.",
      card_not_supported: "This card is not currently supported. Please try another card.",
      duplicate_transaction:
        "A transaction with identical amount and credit card information was submitted very recently. Check to see if a recent payment already exists.",
      do_not_honor:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      do_not_try_again:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      currency_not_supported: "This card does not support the specified currency. Please try another card.",
      "There was an error please use your link again.":
        "There was an error. Please try using your link in a different browser tab.",
    },
    PaymentCrisis: {
      payment: "Payment",
      wouldYouLikeToCompleteThePurchase: "Would you like to complete the purchase?",
      purchaseComplete: "Purchase Complete",
      creditCardInformation: "Credit card information",
      cardHolderName: "Cardholder name",
      required: "Required field",
      yourPaymentHasBeenSuccessfullyRegistered: "Your payment has been successfully registered",
      paymentSuccess: "Payment Success",
      purchasedCompleted: "Purchased Completed",
      pay: "Pay",
      validate: "Validate code",
      cancel: "Cancel",
      retry: "Use another code",
      accept: "Accept",
      writeValidCode: "Write a valid code",
      checkingStudentPayment: "Checking for previous payment",
      studentAlreadyPayedMessage: "Your payment has been verified",
      checkPreviousPaymentError: "There was an error checking for payment",
      "You've already payed for this simulation": "You've already payed for this simulation.",
      errorPostingPayment: "There was an error handling your payment please try again or use a different card.",
      expired_card: "The card has expired. Please try another card.",
      fraudulent: "Your card was declined. Please contact your card issuer for more information.",
      generic_decline: "Your card was declined. Please contact your card issuer for more information.",
      card_declined: "Your card was declined. Please contact your card issuer for more information.",
      insufficient_funds: "Your card has insufficient funds.",
      transaction_not_allowed:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      incorrect_cvc: "The CVC number is incorrect. Please check your card information.",
      invalid_cvc: "The CVC number is incorrect. Please check your card information.",
      card_not_supported: "This card is not currently supported. Please try another card.",
      duplicate_transaction:
        "A transaction with identical amount and credit card information was submitted very recently. Check to see if a recent payment already exists.",
      do_not_honor:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      do_not_try_again:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      currency_not_supported: "This card does not support the specified currency. Please try another card.",
      "There was an error please use your link again.":
        "There was an error. Please try using your link in a different browser tab.",
      ProcessPaymentController_OnlyProfessorCreateSimulation: "Only a professor can pay to create a simulation",
      ProcessPaymentController_MissingPaymentSummary:
        "We couldn't find the simulation that you are payment for, please verify with you professor that he created the simulation.",
      ProcessPaymentController_PaymentFailed:
        "Your payment was declined, please try again. If this happens again please contact us using the support chat.",
      StudentHasPaidController_SimulationNotFound:
        "Oops, it appears that the simulation you are trying to join does not exist, please contact your professor and make sure you are using the correct link.",
      StudentHasPaidController_PaymentSummaryNotFound:
        "Oops, we couldn't find the simulation that you are payment for, please verify with you professor that he created the simulation.",
      StudentHasPaidController_StudentHasNotPaid: "You will be redirected to complete the payment process.",
    },
    PaymentSupplyChain: {
      payment: "Payment",
      wouldYouLikeToCompleteThePurchase: "Would you like to complete the purchase?",
      purchaseComplete: "Purchase Complete",
      creditCardInformation: "Credit card information",
      cardHolderName: "Cardholder name",
      required: "Required field",
      yourPaymentHasBeenSuccessfullyRegistered: "Your payment has been successfully registered",
      paymentSuccess: "Payment Success",
      purchasedCompleted: "Purchased Completed",
      pay: "Pay",
      validate: "Validate code",
      cancel: "Cancel",
      retry: "Use another code",
      accept: "Accept",
      writeValidCode: "Write a valid code",
      checkingStudentPayment: "Checking for previous payment",
      studentAlreadyPayedMessage: "Your payment has been verified",
      checkPreviousPaymentError: "There was an error checking for payment",
      "You've already payed for this simulation": "You've already payed for this simulation.",
      errorPostingPayment: "There was an error handling your payment please try again or use a different card.",
      card_declined: "Your card was declined.",
      insufficient_funds: "Your card has insufficient funds.",
      transaction_not_allowed:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      incorrect_cvc: "The CVC number is incorrect. Please check your card information.",
      invalid_cvc: "The CVC number is incorrect. Please check your card information.",
      card_not_supported: "This card is not currently supported. Please try another card.",
      duplicate_transaction:
        "A transaction with identical amount and credit card information was submitted very recently. Check to see if a recent payment already exists.",
      do_not_honor:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      do_not_try_again:
        "Your payment was declined. Please contact your card issuer for more information or try another card.",
      currency_not_supported: "This card does not support the specified currency. Please try another card.",
      "There was an error please use your link again.":
        "There was an error. Please try using your link in a different browser tab.",
      ProcessPaymentController_OnlyProfessorCreateSimulation: "Only a professor can pay to create a simulation",
      ProcessPaymentController_MissingPaymentSummary:
        "We couldn't find the simulation that you are payment for, please verify with you professor that he created the simulation.",
      ProcessPaymentController_PaymentFailed:
        "Your payment was declined, please try again. If this happens again please contact us using the support chat.",
      StudentHasPaidController_SimulationNotFound:
        "Oops, it appears that the simulation you are trying to join does not exist, please contact your professor and make sure you are using the correct link.",
      StudentHasPaidController_PaymentSummaryNotFound:
        "Oops, we couldn't find the simulation that you are payment for, please verify with you professor that he created the simulation.",
      StudentHasPaidController_StudentHasNotPaid: "You will be redirected to complete the payment process.",
    },
    PaymentForio: {
      payment: "Payment",
      purchaseComplete: "Purchase Complete",
      paymentSuccess: "Activation Success",
      cardHolderName: "Cardholder name",
      required: "Required field",
      yourPaymentHasBeenSuccessfullyRegistered: "Your product has been successfully activated",
      purchasedCompleted: "Purchased Completed",
      discountCode: "Use Activation Code",
      removeDiscountCode: "Remove Activation Code",
      yourCode: "Your activation code...",
      validate: "Validate code",
      cancel: "Cancel",
      retry: "Use another code",
      accept: "Accept",
      ValidateDiscountCodeController_DiscountCodeNotFound: "No matching activation code found.",
      discountSuccess: "Your activation code has been validated successfully.",
      writeValidCode: "Write a valid code",
      errorValidatingDiscountCode:
        "An error has occurred while validating your activation code, please check your Internet connection and reload the page.",
      checkingStudentPayment: "Checking for previous payment",
      studentAlreadyPayedMessage: "Your payment has been verified",
      checkPreviousPaymentError: "There was an error checking for payment",
      "You've already payed for this simulation": "You've already payed for this simulation.",
      ValidateDiscountCodeController_DiscountCodeExpired: "This activation code has already expired.",
      ValidateDiscountCodeController_ExceededNumberOfLicenses:
        "You've exceeded the number of licenses you can create with your activation code.",
      ValidateDiscountCodeController_DiscountCodeDoesntBelong:
        "This activation code is meant to be used with another professor or student.",
      activationCodeHelpText:
        "Input your Activation Code below to set up your simulation. If you want to learn about this simulation and how to get an Activation Code please contact us at support@processimlabs.com.",
    },
    PaymentVerificationModal: {
      retry: "Retry",
      title: "Confirming Payment...",
      defaultErrorMessage:
        "Oops, there was an error confirming your payment. Please make sure you have internet access and try again. If this doesn't work, please contact us using the chat functionality or send us an email to support@processimlabs.com.",
    },
    ProfileScreen: {
      profileDetails: "Profile Details",
      language: "Language",
      fullName: "Full Name",
      email: "Email",
      institution: "Institution",
      requestFeature: "How can we improve?",
      describeYourFeature: "Describe your feature...",
      send: "Send",
      featureDescription: "Feature description",
      pleaseDescribeYourFeature: "Please describe your feature.",
      tryAgain: "Try again",
      featureRequestedSuccessfully: "Your feature has been successfully requested.",
      errorRequestingFeature:
        "An error occurred while requesting your feature, please check your internet connection and reload the page.",
      product: "Product",
    },
    Purchased: {
      numbersStudents: "Number of students",
      course: "Course",
      whoPaysSelected: "Who will be paying?",
      knowsDateSelected: "Do you know the start date of the simulation?",
      next: "Next",
      notValidNumber: "It must be a number",
      startDate: "Start date",
      endDate: "End date",
      simulationName: "Name your simulation",
      notValidNumberMin: "The number of students must be minimum 4",
      notEnoughStudentErrorSimunation: "The number of students must be minimum 1",
      required: "Required field",
      pleaseChooseOne: "Please choose one",
      setDates: "Please set date",
      notSimulationName: "Please give your simulation a name.",
      continue: "Continue",
      creatingSimulation: "Creating Simulation",
      simulationAddedSuccessfully: "Simulation added successfully",
      "Failed to initialize simulation. Please contact support.":
        "Failed to initialize simulation. Please contact support.",
      "Simulation name has already been registered": "Simulation name has already been registered.",
      errorCreatingSimulation:
        "An error has occurred creating your simulation, please check your Internet connection and reload the page.",
      verifyFieldsCorrectlyFilled: "Please verify that all fields are correctly filled.",
      professorAdvanceSimulation: "Manage simulation progress manually?",
      simunationWhoAdvancesSimulation: "Who will manage the progress of the simulation?",
      simunationOptionProfessor: "Professor",
      simunationOptionStudents: "Students",
      medicaProfessorAdvanceSimulationHelp:
        'If you pick "No", the simulation will advance automatically every hour during the time frame of your choosing. If you pick "Yes", you will manually advance the simulation yourself',
      simunationProfessorAdvanceSimulationHelp:
        'If you select "Students" each team will be in charge of deciding when to advance their simulation. If you select "Professor" you will decide when to advance all simulations simultaneously.',
      daysToPlay: "Student interaction days",
      daysToPlayHelp:
        "We recommend 100 virtual days for regular classes (2 - 3 hours), 200 virtual days for a full-day session, and 300 virtual days for sessions lasting longer than one day.",
      errorGettingScenarios: "Custom scenarios not available at the time.",
      advancedOptionsButtonText: "Advanced Options",
      secondRunWarningMessage:
        "The simulation you are about to set up will have the same students and team composition as the current one. If there are students yet to be registered on the previous run please wait until they do so.",
      secondRunParagraphPartOne:
        "Set up your next run of Medica Scientific. You can configure it as it best suits you below.",
      secondRunParagraphPartTwo:
        "Keep in mind that the simulation you are about to set up will have the same students and team composition as the current one. If there are students yet to be registered on the previous run please wait until they do so.",
      invalidSimulationNameCharacterError: `Valid Characters include: A-z, 0-9, '_', and '-'`,
      "Simulation must start at least 1 hour in the future from now":
        "Simulation must start at least 1 hour in the future from now.",
      "Total student interaction days can't be less than 100 days":
        "Total student interaction days can't be less than 100 days.",
      "Total student interaction days can't be more than 300 days":
        "Total student interaction days can't be more than 300 days.",
      "Your account is setup as a student's account. Please contact the developers.":
        "Your account is setup as a student's account. Please contact us at support@processimlabs.com to get assistance.",
      "There has been an authentication error, please contact us to get assistance.":
        "There has been an authentication error. Please contact us at support@processimlabs.com to get assistance.",
      "Please add a simulation name": "Please give your simulation a name.",
      "End Date must be after Start Date": "End Date must be after Start Date.",
      "Simulation must run for at least 6 hours": "Simulation must run for at least 6 hours.",
      "Simulation can only run for 7 days or less": "Simulation can only run for 7 days or less.",
      "A simulation with this name already exists in our database. We suggest using a more specific name.":
        "A simulation with this name already exists in our database. We suggest using a more specific name.",
      "Template not available. Please contact us at support@processimlabs.com.":
        "Simulation couldn't be created. Please contact us at support@processimlabs.com to get assistance.",
      "Advance Simulation Pace should be a positive number": "Advance Simulation Pace should be a positive number.",
      "Days per step is less than one, please run for a shorter amount of time":
        "Days per step is less than one, please run for a shorter amount of time.",
      "Unable to create a simulation at this time. Error 421":
        "Unable to create a simulation at this time. Please contact us at support@processimlabs.com to get assistance.",
      "Failed to initialize simulation. Please contact us at support@processimlabs.com":
        "Failed to initialize simulation. Please contact us at support@processimlabs.com to get assistance.",
    },
    PushNotificationsScreen: {
      title: "Title",
      message: "Message",
      sendNotification: "Send Notification",
      allTeams: "All Teams",
      selectTeam: "Select a team",
      pushNotificationSuccess: "The push notification has been sent successfully.",
      errorPushing:
        "An error has occurred while sending the notification, please check your Internet connection and try again.",
      noTeamsList: "No teams have been created yet.",
      errorFetchingTeams:
        "An error has occurred while fetching the teams, please check your Internet connection and reload the page.",
      verifyFieldsCorrectlyFilled: "Please verify that all fields are correctly filled.",
      required: "Required field",
      "Simulation not found for current user": "Simulation not found for current user.",
      "Simulation not owned by this user": "Simulation not owned by this user.",
      pushNotificationHelpMessage:
        "Send a notification to one or all participant teams directly to their mobile phones.",
      suggestionsSubTitle1:
        "Below you can find some of the issues each of the teams are having at this moment. You can use this information to help your students identify and address these problems if you want. Clicking on any of the suggestions will automatically copy the message to the Push Notifications section on the left so that you can easily send the message.",
      suggestionsSubTitle2:
        '*These are the same messages your students would receive if you turned "In-App Suggestions" on when setting your simulation up.',
    },
    RegisteredStudentsScreen: {
      registeredStudentsOf: "Registered Students",
      userName: "Username",
      name: "Name",
      email: "Email",
      students: "Students",
      loadingRegisteredStudents: "Loading Registered Students, please wait...",
      notStudentList:
        "No student has registered so far. You will be able to monitor student registrations from this section",
      errorFetchStudents:
        "An error has occurred while fetching the students, please check your Internet connection and reload the page.",
      "User does not have this simulation": "User does not have this simulation.",
    },
    MedicaAdvancedOptions: {
      advancedOptionsTitle: "Advanced Options",
      advancedOptionsDescription1:
        "Choose the scenario you want your students to play. These scenarios differ in market price behaviour, products demand, productivity and price of the machines among other variables. If you have a specific set of conditions you wish your students to face, please contact us at: support@processimlabs.com and we will do our best to create it for you.",
      advancedOptionsDescription2:
        "Additionally, you can add small variations to your chosen scenario in case you want the scenario to be slightly but not significantly different from the last time you used it. ",
      recommended: "RECOMMENDED",
      easyDifficultyTemplateName: "Easy",
      easyDifficultyTemplateDescription:
        "Scenario meant to be used by undergrad students without a lot of experience with simulations.",
      mediumDifficultyTemplate: "Medium",
      mediumDifficultyTemplateDescription: "This scenario is recommended for first time users.",
      hardDifficultyTemplate: "Hard",
      hardDifficultyTemplateDescription: "Only students with a good understanding of simulations should use this.",
      customOnlyDifficultyTemplate: "Intro Scenario - Custom Line Only",
      customOnlyTemplateDescription:
        "For this introductory scenario, the student will manage the Custom Line exclusively",
      loadingScenarioComparison: "Loading Scenario Comparison, please wait...",
      noScenarioInformation: "No comparison scenarios to show.",
      errorFetchScenarioInformation:
        "An error has occurred while fetching the scenario information, please check your Internet connection and reload the page.",
      "User does not have this simulation": "User does not have this simulation.",
      scenarioComparison: "Scenario Comparison",
      ATemplate: "Easy",
      BTemplate: "Medium",
      CTemplate: "Hard",
      DTemplate: "Intro",
      StandardLine: "Standard Line",
      CustomLine: "Custom Line",
      Inventory: "Inventory",
      InventoryUnitsPerOrder: "Parts of Inventory per Unit",
      StandardCostPerOrder: "Order Placement Fee",
      StandardStationOneCapacity: "Station 1 - Machine Capacity (units/day)",
      HoldingCost: "Holding Cost per Unit",
      RookieSalary: "Rookie Salary",
      ExpertSalary: "Expert Salary",
      ExpertProductivity: "Expert Capacity (units/day)",
      StandardMarketPrice: "Market Price",
      PriceVsDemand: "Demand (Linear interpolation) (price ratio = product price / market price)",
      CustomDemand: "Custom Line Demand",
      WIPLimit: "WIP Limit",
      CustomStationTwoCapacity: "Station 2 - Machine Capacity (units/day)",
      CustomStationThreeCapacity: "Station 3 - Machine Capacity (units/day)",
      CustomProductPrice: "Product Price (Linear interpolation)",
      InventoryOrderCost: "Order Fee",
      InventoryUnitCost: "Part Cost",
      InventoryDeliveryTime: "Delivery Time",
      Distribution: "Distribution: ",
      LogNormalDistribution: "Log normal",
      TriangularDistribution: "Triangular",
      Days: "day",
      Days_plural: "days",
      Units: "unit",
      Units_plural: "units",
      Parts: "part",
      Parts_plural: "parts",
      DollarsPerDay: "{{dollarValue}}/day",
      UnitsPerDay: "{{value}} unit/day",
      UnitsPerDay_plural: "{{value}} units/day",
      Orders: "order",
      Orders_plural: "orders",
      lowerBound: "Lower Bound: ",
      upperBound: "Upper Bound: ",
      mode: "Mode: ",
      mean: "Mean: ",
      stdDev: "Standard deviation: ",
      graphBelow: "Graph Below",
      playerConfigurable: "Player Decisions (Initial values)",
      StandardOrderQuantity: "Order Quantity",
      StandardOrderFrequency: "Order Frequency",
      StationOneCapacityAllocation: "Station One Capacity Allocation",
      StandardProductPrice: "Product Price",
      StationTwoAllocation: "Station Two Allocation",
      InventoryReorderPoint: "Reorder Point",
      InventoryReorderQuantity: "Reorder Quantity",
      WorkForce: "Workforce",
      NumberOfEmployees: "Number Of Employees",
      Employees: "employees",
      Employees_plural: "employee",
      unitsIfPriceRatio: "{{ units }} units if price ratio is {{ ratio }}",
      addVariationInformationModalTitle: "New features in Medica Scientific!",
      addVariationInformationModalSubtitle: "Introducing small variations to your scenarios.",
      addVariationInformationModalBody1:
        "Now you can choose to add small variations to your selected scenario. This will slightly modify some variables within the simulation like Standard Market Price, Custom Demand, machine and employee capacity; but not impact their overall behaviour. In other words, we are just randomizing the noise component on top of the main waveforms.",
      addVariationInformationModalBody2: "Both of these new features are available in the Advanced Options section.",
      addVariationInformationModalBody3: "This new feature is available in the Advanced Options section.",
      neverShowThisAgain: "Never show this again",
      selectYourScenario: "Select your scenario: ",
      addVariation: "Add variation.",
      addSuggestions: "Enable in-app suggestions:",
      addSuggestionsExplanation:
        "Our mobile app can give your students feedback while they are playing our simulation game. These suggestions will help them realize when a problem has arisen in their virtual company. If you choose to turn suggestions on, our app will sporadically notify your students if their company has issues like inventory stock-outs, bottlenecks, high employee turnover, too many accumulated orders, high Custom line lead times, low employee utilization or if they are selling their Standard products too cheap.",
      addSuggestionsInformationModalSubtitle: "In-app suggestions",
      addSuggestionsInformationModalBody:
        "Our mobile app can now notify your students when issues arise during their simulation exercise. If you choose to turn suggestions on, our app will sporadically notify your students if their company has issues like inventory stock-outs, bottlenecks, high employee turnover, etc.",
      newFeaturesInRegularAndEasyScenarioSubtitle: "Holding Costs on Medium Scenario",
      newFeaturesInRegularAndEasyScenarioBody:
        "Our default Medium Scenario now includes holding costs on all queues and inventory. Units stored in these places will have an associated fixed cost per day stored. This should disincentivise students from hoarding units in their production lines or having poor inventory management policies.",
    },
    ScenarioManagerScreen: {
      scenarioManager: "Scenario Manager",
      loadingProfessorScenarioList: "Loading your scenario list, please wait...",
      noProfessorScenarioList:
        "You can create custom scenarios for Medica Scientific, you will see your created scenarios in this section.",
      errorFetchProfessorScenarioList:
        "An error has occurred while fetching you scenario list, please check your Internet connection and reload the page.",
      scenarioManagerIntro:
        "Welcome to the Scenario Manager. Here you will be able to create custom scenarios for your simulations. This tool is available for Medica Scientific only.",
      scenarioName: "Scenario Name",
      createdAt: "Created At",
      lastUsedDate: "Last time used",
      timesUsed: "Times used",
      edit: "Edit",
      delete: "Delete",
      close: "Close",
      createScenario: "Create new scenario",
      deleteScenario: "Delete Scenario",
      scenarioDeletedSuccesfully: "Your scenario has been deleted successfully.",
      errorDeletingScenario: "An error has occurred while deleting your scenario, try again.",
      errorFetchProfessorDeleteScenario:
        "An error has occurred while deleting your scenario, please check your Internet connection and reload the page.",
      areYouSureYouWantDeleteScenario: "Are you sure do you want delete your scenario <1>{{scenarioName}}</1>?",
    },
    ShareModal: {
      title: "Share your simulation",
      share:
        "Share this link with the team leaders in your class. We have sent you an email with additional information and instructions for you to forward to your students on how to get started.",
      shareStudent:
        "Share this link with the classmates that you want joining your team. We have sent additional information to your email.",
      copy: "Copy to Clipboard",
      cancel: "Cancel",
      copySuccess: "Your link was copied to clipboard.",
      shareActivationCode: "Use this activation code to share your simulation.",
    },
    SimunationDetailScreen: {
      simulationDetails: "Simulation Details",
      activationCode: "Activation Code",
      standings: "Standings",
      registeredStudents: "Registered Students",
      errorFetchDetails:
        "An error has occurred while fetching the information, please check your Internet connection and reload the page.",
      "Simulation not found": "Simulation not found.",
      userAdvanceConfirmation: "Are you sure you want to advance the simulation 5 years?",
      advancingSimulation: "Advancing simulation",
      simulationAdvancedSuccessfully: "Your simulation has been advanced 5 years successfully.",
      simunationCurrentDayHelp:
        "This is the current year of the team that has advanced the furthest in the simulation.",
    },
    StandingsScreen: {
      standings: "Standings",
      cash: "Cash",
      teamCode: "Team Code",
      teams: "Teams",
      loadingStandings: "Loading Standings, please wait...",
      notStandingsList: "No team has registered for this simulation.",
      errorFetchStandings:
        "An error has occurred while fetching the standings, please check your Internet connection and reload the page.",
      score: "Score",
      gdp: "GDP",
      year: "Year",
      standingsHelpMessage: "Teams get ranked based on their company's cash balance.",
      standingsSimunationHelpMessage:
        "The score of a team is calculated based on its relative performance to other teams in all 3 areas: SPI, GDP and cash.",
      totalTeams: "Total teams",
      "Simulation not owned by this user": "Simulation not owned by this user.",
      shareModalInformation: "Use this link to share the simulation standings with the world.",
      shareModalCopy: "Copy",
      shareModalCopySuccess: "Your link was copied to clipboard.",
      share: "Share",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      joinTeamLink: "Team Link",
    },
    StudentCreateTeam: {
      createTeamDescription: "Choose a name for your team",
      teamName: "Team name",
      "Team already exists.": "Team name already exist. Please try with a different team name.",
      createTeam: "Create Team",
      creatingTeam: "Creating Team",
      createTeamSuccess: "Team created successfully!",
      play: "Start Playing!",
      sureYouWantCreateTeam: "Are you sure you want to create team",
      welcomeToProcessimLabs: "Welcome to Processim Labs",
      youAreAboutToCreateATeam: "You are about to create a team for our simulation Medica Scientific.",
      wereYouAssignedAsATeamLeader: "Were you assigned as a team leader? (There can only be one per team).",
      "You can only create one team.":
        "You can only create one team per simulation. We will now redirect you to our FAQ in case you have additional questions.",
      errorCreatingTeam:
        "There was an error trying to create your team, check your Internet connection and reload the page.",
      "Simulation not found":
        "Simulation not found, please confirm that the link provided by your professor is the correct.",
      "Simulation missing. Check code.":
        "Simulation not found, please confirm that the link provided by your professor is the correct.",
      "There has been an authentication error, please contact us to get assistance.":
        "There has been an authentication error. Please contact us at support@processimlabs.com to get assistance.",
      "Server is currently busy please try again.": "Server is currently busy please try again.",
      "Invalid activation code.":
        "Invalid activation code. Please confirm that the link provided by your professor is the correct.",
      "Max number of registrations exceeded. Please contact your professor.":
        "Max number of registrations exceeded. Please contact your professor.",
      "Activation code has already expired. Please contact your professor.":
        "Activation code has already expired. Please contact your professor.",
      "Unable to create team at this time. Error 422":
        "Unable to create team at this time. Please contact us at support@processimlabs.com to get assistance.",
      invalidTeamNameCharacterError: `Valid Characters include: A-z, 0-9, '_', and '-'`,
      retry: "Retry",
    },
    StudentInstructionsModal: {
      teamLeaderInstructions: "Team lead instructions",
      studentInstructions: "Team member instructions",
      leaderIntroduction: "Awesome! Here is what you need to do now:",
      noLeaderIntroduction: "In that case, here is what you need to do:",
      afterDismissingThisPopUpYouWillNeedCreateOrLogin:
        "After dismissing this pop up you will need to create or login to your account with us.",
      thenEnterTheNameOfYourChoiseForYourTeam: "Enter the name of your choice for your team.",
      youWillBePresentedWithAUniqueLink:
        "You will be presented with a unique link. Please share it with your teammates for them to join you. This link is private and shouldn't be shared outside of your team.",
      downloadOurMobileAppAndLogIn:
        "Download our mobile app and log in using the same username and password you used on our website.",
      enjoyManagingYourVirtualCompany: "Enjoy managing your virtual company!",
      waitForYourTeamLeadToShareTheTeamsUniqueLinkWithYou:
        "Wait for your team lead to share the team's unique link with you.",
      clickOnTheLinkToSetUpYourAccountOnOurWebsite: "Click on the link to set up your account on our website.",
      youCanNowDownloadTheMobileAppOnYourSmartphone:
        "You can then download the mobile app on your smartphone. The mobile app is accessed with the same username and password that you used to set up your account on our website.",
    },
    StudentJoinTeamScreen: {
      joiningTeam: "Joining team...",
      joinTeamSuccess: "You've successfully joined team:",
      errorJoiningTeam: "There was an error please try again.",
      "Simulation missing. Check code.": "Simulation not found, please check your team code.",
      "User already registered in this team":
        "You are already part of this team and can start using the simulation from our mobile app. We will now redirect you to our FAQ in case you have additional questions.",
      sureYouWantJoinTeam: "Are you sure you want to join team",
      noTeamMessageError:
        "There are not team name, please try enter again with the link provided by your Team Leader or your Professor.",
      noJoinTeamTeamCode:
        "There was an error while attempting to join team. Please use the link provided by your teammate once again. If this happens again, please contact us at support@processimlabs.com",
    },
    StudentsLoginScreen: {
      itSeemsYouAreNotInTheRightPlace:
        "It seems you are not in the right place. Please read the FAQ below to find what you should do next.",
      areYouTryingToJoinSimulationQuestion: "Are you trying to join a simulation?",
      areYouTryingToJoinSimulationResponse:
        "Your professor or team leader should provide you with a link to get started.",
      haveYouAlreadyJoinedATeamQuestion:
        "You already joined a team through our website but don't know what to do next?",
      haveYouAlreadyJoinedATeamResponse:
        "You should download our app, Medica Scientific, from the Android or Apple store and login with the same username and password you used to access our website.",
      doYouWantToChangeTeamNameQuestion: "Do you want to change the name of an existing team?",
      doYouWantToChangeTeamNameResponse: "Unfortunately, you will have to play with the selected name.",
      areYouTryingToCreateSecondTeamQuestion: "Are you trying to create a second team?",
      areYouTryingToCreateSecondTeamResponse: "Each team leader can only create one team.",
      doYouWantToSwitchTeamsQuestion: "Do you want to switch teams?",
      doYouWantToSwitchTeamsResponse:
        "Just ask the leader for the unique team link and you will be moved to the new team. Be aware that you can only be assigned to one team at a time.",
      canIBePartOfTwoTeamsQuestion: "Can I be part of two teams in the same simulation?",
      canIBePartOfTwoTeamsResponse: "No, you can only be assigned to one team at a time.",
      canIAccessTheAppBeforeSimulationStartsQuestion: "Can I access the app before the simulation starts?",
      canIAccessTheAppBeforeSimulationStartsResponse:
        "Yes, you can access the app but you won't be able to make any changes to it until the simulation starts.",
      howDoIKnowSimulationStartsEndsQuestion: "How do I know when my simulation starts/ends?",
      howDoIKnowSimulationStartsEndsResponse: "Your professor should provide you with this information.",
      contactUs:
        "If you still have questions you can contact us through the chat on the bottom right or by writing to ",
      supportEmail: "support@processimlabs.com.",
      howDoISwitchFromAPreviousSimulationQuestion: "How do I switch from a previous simulation into a new one?",
      howDoISwitchFromAPreviousSimulationResponse: `After your professor lets you know that he or she have already created the new simulation, in the Medica Scientific mobile app go to Settings and tap on "Switch Simulation".`,
      FAQ: "FAQ",
    },
    SupplyChainAdvancedOptions: {
      defaultScenarioTitle: "Default Scenario.",
      defaultScenarioDescription: "This scenario is recommended for first time users.",
      selectYourScenario: "Select your scenario: ",
    },
    SupplyChainAdvanceSimulationModal: {
      StartSimulationController_SimulationEmpty:
        "There are no students currently enrolled in this simulation. Please wait for your students to join before starting the simulation.",
      StartSimulationController_MoreTeamThanStudents:
        "Please pick a number of teams that is less than the number of registered students.",
      StartSimulationController_InsufficientTeams:
        "Please pick a greater number of teams. The maximum number of students in the same team should be 4 or less",
      StartSimulationController_FailedStartTeam:
        "The process of starting the simulation has failed. Please try again. If this happens again please contact us using the support chat.",
      StartSimulationController_SimulationNotFound:
        "We couldn't find the simulation you are trying to start. Please try again. If this happens again please contact us using the support chat.",
      AdvanceSimulationController_SimulatinNotFound:
        "We couldn't find the simulation you are trying to advance. Please try again. If this happens again please contact us using the support chat.",
      AdvanceSimulationController_SimulationFinished: "The simulation you are trying to advance has already finished",
      AdvanceSimulationController_FailedAdvanceSimulation:
        "We couldn't advance the simulation. Please try again in a couple of minutes.",
      AdvanceSimulationController_SimulationDoesntBelong: "It seems that the simulation is used by another professor.",
      AdvanceSimulationController_StartSimulation: "The simulation hasn't started yet. Please start simulation first.",
      NumberOfTeams: "Number of teams",
      NumberOfWeeks: "Number of weeks",
      StudentsAreJoined: "Please make sure all your students have joined the simulation before starting.",
      RegisteredStudents: "Registered students:",
      RecommendedNumberOfTeams: "Recommended number of teams:",
      RoundedUp: "N/4 rounded up",
      MissingSpotsMessage: "Missing spots will be filled by bots",
    },
    SupplyChainBuySimulation: {
      creatingSimulation: "Creating Simulation...",
      numberOfStudentsError: "The number of students should be at least 4.",
      numberOfStudents: "Number of students",
      CreateSimulationController_MissingPayment: "We couldn't verify your payment, please contact us using the chat.",
      SimulationNameInUse:
        "Oops, that simulation name has already been taken, we suggest using something more specific",
      SimulationNameTooLong: "Simulation's name is too long, please try with a shorter name of maximum 25 characters.",
      whoPaysSelected: "Who will be paying?",
      pleaseChooseOne: "Please choose one",
      whoPaysHelp:
        "The cost of the licenses can be covered by the institution or by each individual student when signing up",
    },
    SupplyChainCommon: {
      OneStationUnknownPeers: "One section - unknown peers",
      OneStationKnownPeers: "One section - known peers",
      AllStationsKnownPeers: "All sections - Known Peers",
      Decision_RiceSupplier: "Rice Supplier",
      Decision_RiceReorderQuantity: "Rice Reorder Quantity",
      Decision_RiceReorderPoint: "RiceReorderPoint",
      Decision_AgaveReorderQuantity: "AgaveReorderQuantity",
      Decision_RicelyOrder: "Ricely Order",
      Decision_KrispsOrder: "Krisps Order",
      Decision_RicelyOrderSize: "Ricely Order Size",
      Decision_KrispsOrderSize: "Krisps Order Size",
      Decision_MachinesRicely: "Machines Ricely",
      Decision_MachinesKrisps: "Machines Krisps",
      Decision_RicelyEmployees: "Ricely Employees",
      Decision_KrispsEmployees: "Krisps Employees",
      Decision_RicelyShiftLength: "Ricely Shift Length",
      Decision_KrispsShiftLength: "Krisps Shift Length",
      Decision_LMRicelyReorderQuantity: "Ricely Reorder Quantity",
      Decision_LMRicelyReorderPoint: "Ricely Reorder Point",
      Decision_LMKrispsOrderSize: "Krisps Order Size",
      Decision_RicelyOrderSize: "Ricely Order Size",
      Decision_KrispsOrderSize: "Krisps Order Size",
      Decision_AuroraTransportMethod: "Aurora - Transport Method",
      Decision_AuroraDispatcher: "Aurora - Dispatcher",
      Decision_BarcelonaOrderUpToSize: "Barcelona - Order Up To Size",
      Decision_BarcelonaWarehouseState: "Barcelona - Warehouse State",
      Decision_BarcelonaSupplier: "Barcelona - Supplier",
      Decision_BarcelonaTransportMethod: "Barcelona - Transport Method",
      Decision_BarcelonaDispatcher: "Barcelona - Dispatcher",
      Decision_GenevaOrderUpToSize: "Geneva - Order Up To Size",
      Decision_GenevaWarehouseState: "Geneva - Warehouse State",
      Decision_GenevaSupplier: "Geneva - Supplier",
      Decision_GenevaTransportMethod: "Geneva - Transport Method",
      Decision_GenevaDispatcher: "Geneva - Dispatcher",
      Decision_AlamedaWarehouseState: "Alameda - Warehouse State",
      Decision_AlamedaSupplier: "Alameda - Supplier",
      Decision_AlamedaProductPrice: "Alameda - Product Price",
      Decision_AlamedaTransportMethod: "Alameda - Transport Method",
      Decision_BelmontOrderSize: "Belmont - Order Size",
      Decision_BelmontWarehouseState: "Belmont - Warehouse State",
      Decision_BelmontSupplier: "Belmont - Supplier",
      Decision_BelmontProductPrice: "Belmont - Product Price",
      Decision_BelmontTransportMethod: "Belmont - Transport Method",
      Decision_GrantOrderSize: "Grant - Order Size",
      Decision_GrantWarehouseState: "Grant - Warehouse State",
      Decision_GrantSupplier: "Grant - Supplier",
      Decision_GrantProductPrice: "Grant - Product Price",
      Decision_GrantTransportMethod: "Grant - Transport Method",
      SupplyAndAssembly: "Supply And Assembly",
      Production: "Production",
      NetworkA: "Augusta",
      NetworkB: "Buenavista",
      LastMile: "Last Mile",
      GameModeHelp: "Game mode lets you can select how your students will interact the simulation and with each other.",
      GameMode: "Modo de Juego",
      OneStationUnknownPeersHelp:
        "One Section - Unknown Peers: each student will manage only one section of the supply chain but they will not know who are their partners managing the other sections.",
      OneStationKnownPeersHelp:
        "One Section - Known Peers: each student will manage only one section of the supply chain and will also know what team they belong to.",
      AllStationsKnownPeersHelp:
        "All Sections - Known Peers: each student has access to all of the sections of the supply chain and will also know what team they belong to.",
      LastMileHelp:
        "Last Mile: Up to 2 students per team will manage 2 different comercial regions, its logistics and distribution channels.",
      SimulationPerformanceCatalogue_AccumulatedOrders: "Accumulated Orders",
      SimulationPerformanceCatalogue_SupplyAndAssemblyInventory: "Supply And Assembly - Inventory",
      SimulationPerformanceCatalogue_MaterialX: "Rice",
      SimulationPerformanceCatalogue_MaterialY: "Agave",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductA: "Supply And Assembly - Ricely",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductB: "Supply And Assembly - Krisps",
      SimulationPerformanceCatalogue_Production: "Production",
      SimulationPerformanceCatalogue_FinishedGoods: "Finished Goods",
      SimulationPerformanceCatalogue_ProductionProductA: "Production - Ricely",
      SimulationPerformanceCatalogue_IncomingInventory: "Incoming Inventory",
      SimulationPerformanceCatalogue_StationOne: "Station 1",
      SimulationPerformanceCatalogue_ManualLaborQueue: "Manual Labor Queue",
      SimulationPerformanceCatalogue_ManualStation: "Manual Station",
      SimulationPerformanceCatalogue_LaborUtilization: "Labor Utilization",
      SimulationPerformanceCatalogue_ProductionProductB: "Production - Krisps",
      SimulationPerformanceCatalogue_NetworkA: "Augusta Network",
      SimulationPerformanceCatalogue_IncomingInventoryProductAWarehouseAlphaFromLocalSupplier:
        "Aurora Warehouse - Incoming Inventory",
      SimulationPerformanceCatalogue_IncomingInventoryProductAWarehouseBetaFromLocalSupplier:
        "Barcelona Warehouse - Incoming Inventory",
      SimulationPerformanceCatalogue_IncomingInventoryProductAWarehouseGammaFromLocalSupplier:
        "Geneva Warehouse - Incoming Inventory",
      SimulationPerformanceCatalogue_WarehouseAProductA: "Aurora Warehouse - Ricely Level",
      SimulationPerformanceCatalogue_WarehouseBProductA: "Barcelona Warehouse - Ricely Level",
      SimulationPerformanceCatalogue_WarehouseCProductA: "Geneva Warehouse - Ricely Level",
      SimulationPerformanceCatalogue_RegionAProductADemand: "Aurora Warehouse - Demand",
      SimulationPerformanceCatalogue_RegionBProductADemand: "Barcelona Warehouse - Demand",
      SimulationPerformanceCatalogue_RegionCProductADemand: "Geneva Warehouse - Demand",
      SimulationPerformanceCatalogue_RegionAProductABacklog: "Aurora Warehouse - Backlog",
      SimulationPerformanceCatalogue_RegionBProductABacklog: "Barcelona Warehouse - Backlog",
      SimulationPerformanceCatalogue_RegionCProductABacklog: "Geneva Warehouse - Backlog",
      SimulationPerformanceCatalogue_NetworkB: "Buenavista Network",
      SimulationPerformanceCatalogue_IncomingInventoryProductBWarehouseAlphaFromLocalSupplier:
        "Alameda Warehouse - Incoming Inventory",
      SimulationPerformanceCatalogue_IncomingInventoryProductBWarehouseBetaFromLocalSupplier:
        "Belmont Warehouse - Incoming Inventory",
      SimulationPerformanceCatalogue_IncomingInventoryProductBWarehouseGammaFromLocalSupplier:
        "Grant Warehouse - Incoming Inventory",
      SimulationPerformanceCatalogue_WarehouseAProductB: "Alameda Warehouse - Krisps Level",
      SimulationPerformanceCatalogue_WarehouseBProductB: "Belmont Warehouse - Krisps Level",
      SimulationPerformanceCatalogue_WarehouseCProductB: "Grant Warehouse - Krisps Level",
      SimulationPerformanceCatalogue_RegionAProductBDemand: "Alameda Warehouse - Demand",
      SimulationPerformanceCatalogue_RegionBProductBDemand: "Belmont Warehouse - Demand",
      SimulationPerformanceCatalogue_RegionCProductBDemand: "Grant Warehouse - Demand",
      SimulationPerformanceCatalogue_Finance: "Finance",
      SimulationPerformanceCatalogue_SupplyAndAssembly: "Supply And Assembly",
    },
    SupplyChainDetailScreen: {
      simulationDetails: "Simulation Details",
      GetSimulationDetailsController_CantUseAPI: "You don't have permission to use this API, please contact support.",
      GetSimulationDetailsController_SimulatinNotFound:
        "We couldn't find the simulation. Please try again. If this happens again please contact us using the support chat.",
    },
    SupplyChainJoinSimulation: {
      joinSimulationSuccessful: "You have successfully joined the simulation",
      JoinSimulationController_SimulationNotFound:
        "Oops, it appears that the simulation you are trying to join does not exist, please contact your professor and make sure you are using the correct link.",
      failedJoinSimulation: "Failed to join Supply Grain simulation.",
      JoinSimulationController_AlreadyJoined: "You have already joined the simulation",
      JoinSimulationController_MissingPaymentSummary:
        "It seems that the simulation you try to join has not been paid, please verify the payment with your professor",
      JoinSimulationController_ExcededLicenses:
        "Oops, there is no paid license available for you to join the simulation, please verify the payment with your professor",
    },
    SupplyChainModifyModal: {
      ModifySimulation: "Modify Simulation",
      cancel: "Cancel",
      accept: "Apply",
      success: "The simulation has been successfully modified",
    },
    SupplyChainPurchased: {
      advancePeriod: "The simulation will advance every:",
      GameModeTitle: "Select a game mode",
      SimulationStartInTheFuture: "Simulation needs to start at least in half an hour",
      SimulationMinDurationError: "Simulation needs to last longer than {{hours}} hours",
      SimulationMaxDurationError: "Simulation maximum duration is {{hours}} hours",
      TimeBetweenAdvancesOneHour: "1 hour",
      TimeBetweenAdvancesTwoHours: "2 hours",
      AdvancedOptions: "Advanced Options",
    },
    SupplyChainRunAgain: {
      CreateNextRunController_SimulationNotFound:
        "There was an error setting up your simulation, please try again, if that doesn't work please contact us using the chat bubble or via email at support@processimlabs.com.",
      CreateNextRunController_ErrorInitializingSimulation:
        "There was an error initializing your simulation, please try again, if that doesn't work please contact us using the chat bubble or via email at support@processimlabs.com.",
      scrambleTeamMembers: "Change team members: ",
      createNextRunSuccess:
        "Your simulation has been set up correctly. You will be able to access the new simulation from the home page.",
      Success: "Success!",
    },
    SupplyChainShareModal: {
      ShareJoinLInk: "Share this link with your students for them to join your simulation.",
    },
    SupplyChainStandings: {
      Team: "Team",
      Total: "Total Cash",
      SupplyAndAssembly: "Supply & Assembly",
      Production: "Production",
      NetworkA: "Augusta Network",
      NetworkB: "Buenavista Network",
      All: "All",
      EmptyStandings:
        "We are sorry, we couldn't find any standings for your simulation. You should see standings after starting your simulation. Please come back later.",
      "Team Member": "Team Member",
      Email: "Email",
      Role: "Role",
    },
    RunAgainScreen: {
      addedSuccessfully:
        "We are currently copying the students and teams from the previous simulation to this one. This process may take between 5 and 10 minutes. You and your students will be notified by email when it is finished.",
      "Account not found.":
        "Account not found. Please try again or contact us at: support@processimlabs.com to get assistance.",
      "Simulation not found.":
        "Simulation not found. Please try again or contact us at: support@processimlabs.com to get assistance.",
      "Your next run is being created, we will notify you when it's finished.":
        "We have received your request for a second run, you should be able to access it from your Home screen in just a few minutes.",
      "Total student interaction can't be less than 100 days": "Total student interaction can't be less than 100 days.",
      "Total student interaction can't be more than 300 days": "Total student interaction can't be more than 300 days.",
    },
    TutorialsScreen: {
      loadingTutorials: "Loading videos, please wait...",
      noVideosList: "There are currently no videos list available.",
      Simunation: "Simunation",
      MedicaScientific: "Medica Scientific",
      errorFetchVideos:
        "An error has occurred while fetching the your videos, please check your Internet connection and reload the page.",
    },
  },
  es: {
    AbogadosAdvanceSimulationModal: {
      AdvanceSimulationController_SimulationNotFound:
        "No hemos podido encontrar la simulación que intentas avanzar. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      AdvanceSimulationController_SimulationFinished: "La simulación que intentas avanzar ya ha finalizado.",
      AdvanceSimulationController_FailedAdvanceTeam:
        "No pudimos avanzar algunos equipos. Por favor, avanza de nuevo la simulación para intentar actualizar los equipos.",
      AdvanceSimulationController_WrongSimulation: "Parece que la simulación es usada por otro profesor.",
      AdvanceSimulationController_SimulationHasNoTeams:
        "No hay estudiantes registrados en esta simulación. Por favor asegúrate de que los estudiantes se hayan registrado para poder iniciar la simulación.",
      AdvanceSimulationController_CouldNotSaveChanges:
        "No se pudieron guardar los datos de la simulación. Por favor no avance la simulación y contáctenos usando el chat de soporte.",
      StartSimulationMessage:
        "Por favor considere que una vez inicializada la simulación, los estudiantes no podrán crear nuevos equipos, sin embargo podrán unirse a un equipo ya existente.",
      AdvanceMessage: "Avanza la simulación un trimestre.",
      outdatedTeams: "Equipos que no lograron avanzar: ",
    },
    AdvanceSimulationModal: {
      advanceSimulation: "Avanzar Simulación",
      startSimulation: "Comenzar Simulación",
      start: "Iniciar",
      finishSimulation: "Finalizar Simulación",
      advance: "Avanzar",
      remainingDays: "Período restante de interacción del alumno: ",
      currentDays: "Día Actual",
      daysToAdvance: "Días para avanzar",
      futureDays: "Día Futuro",
      warningFinishMessage:
        'Esta es la última oportunidad que tienen sus Estudiantes para hacer cambios dentro de la simulación. Una vez que presione el botón "Finalizar", transcurrirán 50 días virtuales y finalizará la simulación.',
      currentYear: "Año Actual",
      yearsToAdvance: "Años para Avanzar",
      resultingYear: "Año Resultante",
      simulationFinishedSuccessfully: "La simulación finalizó con éxito.",
      "Error. Simulation can only be advanced after at least one team has been created.":
        "La simulación solo se puede avanzar después de que se haya creado al menos un equipo.",
      "Simulation advanced successfully.": "La simulación avanzó con éxito.",
      "Please use a non negative amount of days to simulate": "Utilice una cantidad de días no negativa para simular.",
      "There has been an authentication error, please contact us at support@processimlabs.com":
        "Ha habido un error de autenticación, contáctenos en support@processimlabs.com para obtener ayuda.",
      "Your account is setup as a student's account. please contact us at support@processimlabs.com":
        "Su cuenta está configurada como la cuenta de un estudiante. contáctenos en support@processimlabs.com para obtener asistencia.",
      "You don't seem to own this simulation. Please contact us at support@processimlabs.com.":
        "Parece que no tienes esta simulación. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      "Couldn't find control team":
        "No se pudo encontrar el equipo de control. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      "This simulation advances on its own": "Esta simulación avanza por sí sola.",
      "Simulation is not running.": "La simulación no se está ejecutando.",
      "Nothing to run": "Nada para correr.",
      "There was an error advancing please try again.": "Se produjo un error al avanzar. Inténtalo de nuevo.",
      "Other operation running, try again.":
        "No hemos podido avanzar la simulación en este momento. Por favor pídele a tus estudiantes que no hagan cambios temporalmente mientras avanzas la simulación e inténtalo de nuevo. Gracias por tu paciencia.",
      days: "días.",
      errorAdvancingSimulation:
        "Se ha producido un error al hacer avanzar su simulación, compruebe su conexión a Internet y vuelva a cargar la página.",
      advanceAgain: "Avanzar de nuevo",
      errorSimultaneousChangeRequest: `"¡Uh, oh! Parece que uno de tus alumnos estaba tratando de cambiar algo en la simulación cuando intentaste avanzar. Por favor, hazles saber que estás a punto de intentarlo de nuevo".`,
      BackendSimulation_SimulationLimitError:
        "La simulación ha terminado. Esperamos que hayas disfrutado la experiencia. ¡Gracias por participar!",
    },
    AuthComponents: {
      loadingProfile: "Cargando perfil, por favor espere...",
      loadingPage: "Cargando la página, por favor espere...",
    },
    CatalogueScreen: {
      catalogue: "Catálogo",
      getCreative:
        "Este es nuestro catálogo de simulaciones. No dude en contactarnos si desea obtener más información sobre cualquiera de ellas.",
      select: "Seleccionar",
      back: "Atrás",
      medicaFlavor:
        "Asuma el papel de gerente de una planta de manufactura de dispositivos médicos con dos líneas de producción distintas. Administre sus operaciones, compre maquinaria, contrate empleados, elija el precio de sus productos, ordene inventario, etc.",
      medicaTopics: "Gestión de operaciones, Gestión de inventario, Capacidad y más.",
      simunationFlavor:
        "Aprenda sobre la importancia del crecimiento sostenible y la toma de decisiones informadas mientras gestiona varios sectores de un país virtual como lo son Educación, Producción, Salud, Finanzas, etc.",
      simunationTopics: "Progreso social, Desarrollo sostenible, Economía y más.",
      bindzFlavor:
        "Administre la cadena de suministro de una empresa que manufactura adhesivos especializados mientras enfrenta diversas crisis que afectan sus operaciones según las decisiones tomadas durante la simulación.",
      bindzTopics: "Gestión de la cadena de suministro, Gestión de crisis y más.",
      supplyGrainFlavor:
        "Supply Grain simula la cadena de suministro de una empresa productora de cereales. Tome decisiones en cada uno de los segmentos de la cadena sobre las operaciones y la logística relacionada con la entrega de los bienes producidos a los clientes finales.",
      supplyGrainTopics: "Gestión de la cadena de suministro, Logística, Diseño de redes de distribución y más.",
      abogadosFlavor:
        "Los estudiantes asumen el rol de gerentes de una prestigiosa y bien establecida firma legal con diferentes niveles de empleados, que pueden servir a varios segmentos del mercado.",
      abogadosTopics:
        "Operaciones de servicio, capital humano, estructura organizativa, planificación de capacidad y más.",
      creambowFlavor:
        "Administre de una fábrica de helados. Tome decisiones vitales con respecto a los sabores, tamaños, mercados meta y la producción misma de distintos deliciosos helados.",
      creambowTopics:
        "Estrategia comercial, prioridades competitivas, estrategia de operaciones, planificación de capacidad, análisis de procesos y más.",
      topic: "Temas: {{topicsList}}",
    },
    CreambowAdvanceSimulationModal: {
      AdvanceSimulationController_SimulationNotFound:
        "No hemos podido encontrar la simulación que intentas avanzar. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      AdvanceSimulationController_SimulationFinished: "La simulación que intentas avanzar ya ha finalizado.",
      AdvanceSimulationController_FailedAdvanceTeam:
        "No pudimos avanzar algunos equipos. Por favor, avanza de nuevo la simulación para intentar actualizar los equipos.",
      AdvanceSimulationController_WrongSimulation: "Parece que la simulación es usada por otro profesor.",
      AdvanceSimulationController_SimulationHasNoTeams:
        "No hay estudiantes registrados en esta simulación. Por favor asegúrate de que los estudiantes se hayan registrado para poder iniciar la simulación.",
      AdvanceSimulationController_CouldNotSaveChanges:
        "No se pudieron guardar los datos de la simulación. Por favor no avance la simulación y contáctenos usando el chat de soporte.",
      AdvanceMessage: "Avanza la simulación una quincena.",
      outdatedTeams: "Equipos que no lograron avanzar: ",
    },
    CreateNewScenarioScreen: {
      createNewScenario: "Crear nuevo escenario",
      hasVariance: "¿Tiene varianza?",
      name: "Nombre",
      description: "Descripción",
      seed: "Semilla",
      initialCash: "Efectivo inicial",
      stationOneMachineCapacity: "Capacidad de la máquina de la estación uno",
      stationTwoMachineCapacity: "Capacidad de la máquina de la estación dos",
      stationThreeMachineCapacity: "Capacidad de la máquina de la estación tres",
      inventoryLeadTime: "Tiempo de entrega de inventario",
      batchSetupTime: 'Tiempo de procesamiento en "Loteo Inicial"',
      customLineWipLimit: "Capacidad máxima en la línea Custom",
      customDemandInitialValue: "Valor inicial de demanda en la línea Custom",
      customDemandFinalValue: "Valor final de demanda en la línea Custom",
      customDemandStabilizationDay: "Día de estabilización de demanda en la línea Custom",
      customDemand: "Demanda Custom",
      customDemandHasVariance: "¿Demanda custom tiene variación?",
      required: "Campo requerido",
      stationOneMachineHasVariance: "¿Capacidad de la máquina de la estación uno tiene variación?",
      stationTwoMachineHasVariance: "¿Capacidad de la máquina de la estación dos tiene variación?",
      stationThreeMachineHasVariance: "¿Capacidad de la máquina de la estación tres tiene variación?",
      areYouSureDoYouWantCreateNewScenario:
        "¿Estás seguro de que quieres crear un nuevo escenario con la siguiente información?",
      newScenarioConfirmation: "Confirmación de nuevo escenario",
      create: "Crear",
      scenarioHasBeenCreatedSuccessfully: "Su escenario ha sido creado exitosamente.",
      errorCreatingNewScenario:
        "Se ha producido un error al crear su escenario, compruebe su conexión a Internet y vuelva a cargar la página.",
      scenarioNameAlreadyExist: "Nombre de escenario ya existe",
      "Initial Cash should be between 50K and 250K": "El efectivo inicial debe estar entre 50K y 250K.",
      "Seed should be between 0 and 20000": "La semilla debe estar entre 0 y 20000.",
      "Station 1 Machine Capacity should be between 45 and 90":
        "La capacidad de la máquina de la estación 1 debe estar entre 45 y 90.",
      "Station 2 Machine Capacity should be between 90 and 180":
        "La capacidad de la máquina de la estación 2 debe estar entre 90 y 180.",
      "Station 3 Machine Capacity should be between 120 and 240":
        "La capacidad de la máquina de la estación 3 debe estar entre 120 y 240.",
      "Inventory Lead Time should be between 3 and 8": "El tiempo de entrega del inventario debe estar entre 3 y 8.",
      "Batch Setup Time should be between 3 and 8": "El tiempo de configuración del lote debe estar entre 3 y 8.",
      "Custom Line Wip Limit should be between 600 and 1200":
        "El límite de capacidad de la línea Custom debe estar entre 600 y 1200.",
      "Custom Demand Initial Value should be between 0 and 50":
        "El valor inicial de la demanda Custom debe estar entre 0 y 50.",
      "Custom Demand Final Value should be between 1 and 400":
        "El valor final de la demanda Custom debe estar entre 1 y 400.",
      "Custom Demand Stabilization Day should be between 100 and 300":
        "El día de estabilización de la demanda Custom debe estar entre 100 y 300.",
      "The scenario already exists": "El escenario ya existe.",
      "units/day": "unidades/día",
      days: "días",
      units: "unidades",
      orders: "ordenes",
      UnitInterpolation: "{{count}} unidad",
      UnitInterpolation_plural: "{{count}} unidades",
      DayInterpolation: "{{count}} día",
      DayInterpolation_plural: "{{count}} días",
      OrderInterpolation: "{{count}} pedido",
      OrderInterpolation_plural: "{{count}} pedidos",
      DollarsInterpolation: "$ {{count}}",
      UnitPerDayInterpolation: "{{count}} unidad/día",
      UnitPerDayInterpolation_plural: "{{count}} unidades/día",
      randomize: "Aleatorio",
      seedMustBeBetweenZeroAndTwentyThousand: "Este valor debe estar entre 0 y 20.000.",
      goToList: "Ve a tu lista de escenarios",
      initialCashHelp: "Cantidad de efectivo disponible al comienzo de la simulación.",
      stationOneMachineCapacityHelp: "Cantidad de unidades producidas por cada máquina en esta estación por día.",
      stationTwoMachineCapacityHelp: "Cantidad de unidades producidas por cada máquina en esta estación por día.",
      stationThreeMachineCapacityHelp: "Cantidad de unidades producidas por cada máquina en esta estación por día.",
      inventoryLeadTimeHelp: "Cantidad de días que tarda cada pedido de inventario en llegar.",
      batchSetupTimeHelp: 'Cantidad de días que se tarda en procesar cada lote en "Loteo Inicial".',
      customLineWipLimitHelp:
        "Cantidad máxima de unidades que la línea Custom puede procesar en cualquier momento (WIP Limit).",
      customDemandInitialValueHelp: "Valor inicial de demanda diaria en la línea Custom.",
      customDemandFinalValueHelp: "Valor final de demanda diaria en la línea Custom.",
      customDemandStabilizationDayHelp:
        "Día virtual en el que la demanda de la línea Custom se estabiliza en su valor final.",
      seedValueHelp:
        "La semilla se utiliza para calcular valores aleatorios de la varianza, los escenarios serán ligeramente diferentes si se utilizan distintos valores de semilla.",
    },
    CrisisAdvancedOptions: {
      selectYourScenario: "Selecciona el escenario: ",
      defaultScenarioTitle: "Escenario por defecto",
      defaultScenarioDescription: "Este escenario se recomienda para los usuarios primerizos.",
    },
    CrisisAdvanceSimulationModal: {
      StartSimulationController_SimulationEmpty:
        "No hay estudiantes registrados en esta simulación. Por favor asegúrate de que los estudiantes se hayan registrado para poder iniciar la simulación.",
      StartSimulationController_MoreTeamThanStudents:
        "Por favor escribe un número de equipos que sea menor que la cantidad total de estudiantes registrados.",
      StartSimulationController_InsufficientTeams:
        "Por favor escoge un número de equipos más grande. La cantidad máxima de estudiantes en un mismo equipo debe ser igual o menor que 4.",
      StartSimulationController_FailedStartTeam:
        "Ha fallado el proceso de inicializar la simulación. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      StartSimulationController_SimulationNotFound:
        "No hemos podido encontrar la simulación que intentas comenzar. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      AdvanceSimulationController_SimulatinNotFound:
        "No hemos podido encontrar la simulación que intentas avanzar. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      AdvanceSimulationController_SimulationFinished: "La simulación que intentas avanzar ya ha finalizado.",
      AdvanceSimulationController_FailedAdvanceSimulation:
        "No hemos podido avanzar la simulación, por favor inténtalo nuevamente en unos minutos.",
      NumberOfTeams: "Cantidad de equipos",
      NumberOfWeeks: "Número de semanas",
      AdvanceSimulationController_SimulationDoesntBelong: "Parece que la simulación es usada por otro profesor.",
      AdvanceSimulationController_StartSimulation:
        "La simulación que intentas avanzar no ha comenzado. Por favor comienza la simulación primero.",
      StudentsAreJoined:
        "Por favor asegúrate de que todos tus estudiantes se hayan unido a la simulación antes de comenzar.",
      RegisteredStudents: "Estudiantes registrados:",
      RecommendedNumberOfTeams: "Número recomendado de equipos:",
      RoundedUp: "N/4 redondeado hacia arriba.",
      MissingSpotsMessage: "Las posiciones faltantes seran jugadas por bots.",
    },
    CrisisBadLink: {
      badLink: "Parece que has utilizado el enlace equivocado.",
      tryFollowing:
        "Por favor verifica con tu profesor que el enlace que has utilizado es el correcto, si ese es el caso, no dudes en contactarnos en <1>support@processimlabs.com</1> o utilizando el chat en la esquina inferior derecha de esta página.",
    },
    CrisisBuySimulation: {
      creatingSimulation: "Creando Simulación...",
      numberOfStudentsError: "El número de estudiantes debe ser al menos 4.",
      numberOfStudents: "Número de estudiantes",
      whoPaysSelected: "¿Quién pagará?",
      pleaseChooseOne: "Por favor elige uno",
      whoPaysHelp:
        "El costo de las licencias puede ser cubierto por la institución o por cada estudiante a la hora de registrarse.",
      verifyFieldsCorrectlyFilled: "Por favor verifique que todos los campos estén llenos de forma correcta.",
      CreateSimulationController_MissingPayment:
        "No hemos podido verificar tu pago, por favor contactanos utilizando el chat.",
      SimulationNameInUse:
        "Oops, ese nombre de la simulación ya se ha utilizado, te sugerimos utilizar un nombre de simulación mas específico",
      SimulationNameTooLong:
        "El nombre de la simulación es muy largo, por favor intenta con un nombre más corto de máximo 25 caracteres.",
    },
    CrisisDetailScreen: {
      GetSimulationDetailsController_CantUseAPI: "No tienes permiso para usar este API, por favor contacta a soporte.",
      GetSimulationDetailsController_SimulatinNotFound:
        "No hemos podido encontrar la simulación. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      createNextRun: "Crear Siguiente Corrida",
    },
    CrisisJoinSimulation: {
      joinSimulationSuccessful: "Te has unido satisfactoriamente a la simulación.",
      JoinSimulationController_SimulationNotFound:
        "¡Oh no!, parece que la simulación a la que te has intentado unir no existe. Por favor confirma con tu profesor que has utilizado el link adecuado.",
      failedJoinSimulation: "No se ha podido unir a la simulación de Bindz.",
      JoinSimulationController_AlreadyJoined:
        "Ya te haz unido a esta simulación, para continuar por favor descarga la aplicación Bindz de la tienda de tu celular e inicia sesión.",
      JoinSimulationController_MissingPaymentSummary:
        "Parece que la simulación a la que intentas unirte no ha sido pagada, verifica el pago con tu profesor",
      JoinSimulationController_ExcededLicenses:
        "¡Oh no!, parece que no hay una licencia disponible pagada para que puedas unirte a la simulación, por favor verifica el pago con tu profesor.",
    },
    CrisisCommon: {
      OneStationUnknownPeers: "Una sección - compañeros desconocidos",
      OneStationKnownPeers: "Una sección - compañeros conocidos",
      AllStationsKnownPeers: "Todas las secciones - compañeros conocidos",
      GameModeHelp:
        "Con la selección de modo de juego puedes seleccionar como quieres que tus estudiantes interactúen con la simulación y entre ellos.",
      OneStationUnknownPeersHelp:
        "Una Sección - Compañeros Desconocidos: cada estudiante administrará solamente una sección de la cadena de suministro, pero no sabrán cuales compañeros operan las otras secciones.",
      OneStationKnownPeersHelp:
        "Una Sección - Compañeros Conocidos: cada estudiante administrará solamente una sección de la cadena de suministro y además sabrán a cuál equipo pertenecen.",
      AllStationsKnownPeersHelp:
        "Todas las secciones - Compañeros Conocidos: Cada estudiante tendra acceso a todas las secciones de la cadena de suministro, y además sabrán a cuál equipo pertenecen.",
      SimulationPerformanceCatalogue_SupplyAndAssemblyRawMaterials: "Suministro y Montaje Materia Prima",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductA: "Suministro y Montaje Producto A",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductB: "Suministro y Montaje Producto B",
      SimulationPerformanceCatalogue_ProductionOneProductA: "Producción 1 Producto A",
      SimulationPerformanceCatalogue_ProductionOneProductB: "Producción 1 Producto B",
      SimulationPerformanceCatalogue_ProductionTwoProductA: "Producción 2 Producto A",
      SimulationPerformanceCatalogue_ProductionTwoProductB: "Producción 2 Producto B",
      SimulationPerformanceCatalogue_RetailerOnlineProductA: "Tienda en Línea Producto A",
      SimulationPerformanceCatalogue_RetailerOnlineProductB: "Tienda en Línea Producto B",
      SimulationPerformanceCatalogue_RetailerOfflineProductA: "Tiendas Físicas Producto A",
      SimulationPerformanceCatalogue_RetailerOfflineProductB: "Tiendas Físicas Producto B",
      SimulationPerformanceCatalogue_Finance: "Finanzas",
      SimulationPerformanceCatalogue_MaterialX: "Cantidad de Material X",
      SimulationPerformanceCatalogue_MaterialY: "Cantidad de Material Y",
      SimulationPerformanceCatalogue_Production: "Producción",
      SimulationPerformanceCatalogue_FinishedGoods: "Producto Terminado",
      SimulationPerformanceCatalogue_IncomingInventory: "Inventario Entrante",
      SimulationPerformanceCatalogue_StationOne: "Estación 1",
      SimulationPerformanceCatalogue_ManualStation: "Estación Manual",
      SimulationPerformanceCatalogue_LaborUtilization: "Utilización de Mano de Obra",
      SimulationPerformanceCatalogue_Deliveries: "Entregas",
      SimulationPerformanceCatalogue_Demand: "Demanda",
      SimulationPerformanceCatalogue_SupplyAndAssembly: "Suministro y Montaje",
      SimulationPerformanceCatalogue_ProductionOne: "Producción 1",
      SimulationPerformanceCatalogue_ProductionTwo: "Producción 2",
      SimulationPerformanceCatalogue_Retailer: "Tiendas",
    },
    CrisisDashboards: {
      GetSimulationPerformanceController_SimulationShouldStarted:
        "Gráficos con información acerca de los equipos en esta simulación estarán disponibles en esta sección luego de que la simulación sea iniciada.",
    },
    CrisisModifyModal: {
      ModifySimulation: "Modificar Simulación",
      close: "Cerrar",
      accept: "Aplicar",
      Success: "Se ha modificado la simulación correctamente.",
    },
    CrisisPurchased: {
      GameModeTitle: "Seleccione un modo de Juego",
      SimulationStartInTheFuture: "Seleccione una fecha de inicio al menos una hora en el futuro",
      SimulationMinDurationError: "La simulación debe durar al menos 6 horas",
      SimulationMaxDurationError: "La simulación debe durar máximo 52 horas",
      AdvancedOptions: "Opciones Avanzadas",
    },
    CrisisShareModal: {
      ShareJoinLInk: "Comparte este enlace con tus estudiantes para que puedan unirse a tu simulación.",
    },
    CrisisStandings: {
      standingsTitle: "Posiciones",
      Team: "Equipo",
      Total: "Efectivo Total",
      SupplyAndAssembly: "Suministro y Montaje",
      ProductionOne: "Producción 1",
      ProductionTwo: "Producción 2",
      Retail: "Comercio",
      EmptyStandings:
        "Disculpa, no hemos podido encontrar posiciones para tu simulación. Deberías poder verla luego de inicializar tu simulación. Por favor vuelve luego.",
      All: "Todos",
      "Team Member": "Miembro",
      Email: "Correo",
      Role: "Rol",
      Position: "Posición",
    },
    CrisisRunAgain: {
      CreateNextRunController_SimulationNotFound:
        "Ha habido un error creando la simulación, por favor inténtelo de nuevo. En caso de que esto no funcione contactenos, utilizando la función de chat o escribiendonos un correo a support@processimlabs.com.",
      CreateNextRunController_ErrorInitializingSimulation:
        "Ha habido un error inicializando la simulación, por favor inténtelo de nuevo. En caso de que esto no funcione, contactenos utilizando la función de chat o escribiendonos un correo a support@processimlabs.com.",
      scrambleTeamMembers: "Aleatorizar equipos: ",
      createNextRunSuccess:
        "La nueva simulación se ha configurado correctamente. Podrá acceder toda la información de esta en la página de inicio.",
      Success: "¡Éxito!",
    },
    CookiesDisabledScreen: {
      cookiesNotEnabled:
        "No hay cookies habilitadas en este navegador, por favor habilita las cookies o usa otro navegador.",
    },
    Common: {
      logout: "Cerrar sesión",
      simulation: "Simulación",
      name: "Nombre",
      endDate: "Fecha final",
      status: "Estado",
      previous: "Anterior",
      next: "Siguiente",
      pushNotification: "Notificaciones Push",
      team: "Equipo",
      showing: "Mostrando",
      to: "a",
      of: "de",
      back: "Atrás",
      continue: "Continuar",
      notValid_number: "Debe ser un número",
      yes: "Sí",
      no: "No",
      startDate: "Fecha de inicio",
      students: "estudiantes",
      Students: "Estudiantes",
      institution: "Institución",
      welcome: "Bienvenido",
      Professor: "Profesor",
      Student: "Estudiante",
      accept: "Aceptar",
      cancel: "Cancelar",
      today: "hoy",
      close: "Cerrar",
      error: "Error",
      advanceSimulation: "Avanzar Simulación",
      home: "Inicio",
      confirmation: "Confirmación",
      currentDay: "Día de simulación actual",
      dashboards: "Panel de Monitoreo",
      finish: "Finalizar",
      notice: "Aviso",
      success: "Éxito",
      teams: "Equipos",
      documents: "Documentos",
      warning: "Advertencia",
      setLanguage: "Establece tu idioma",
      Initialized: "Inicializada",
      AwaitingInitialization: "Listo para iniciar",
      Running: "En ejecución",
      Finished: "Finalizada",
      profile: "Perfil",
      Spanish: "Español",
      English: "Inglés",
      tryAgain: "Inténtalo de nuevo",
      unknownBackendErrorMessage:
        "Vaya, no hemos podido completar tu pedido, por favor inténtalo de nuevo. Si vuelves a recibir este mensaje contáctanos por medio nuestro chat.",
      unknownBackendExceptionMessage:
        "Vaya, no hemos podido completar tu pedido, por favor inténtalo de nuevo. Si vuelves a recibir este mensaje contáctanos por medio nuestro chat.",
      scenarioManager: "Gestor de Escenarios",
      "Busy Server": "¡Oh no!, el servidor parece estar ocupado, por favor inténtalo de nuevo en unos momentos.",
    },
    DashBoardsScreen: {
      loadingTeamData: "Cargando la información de los equipos, por favor espere...",
      teamAlreadySelected: "Ya has seleccionado este equipo.",
      errorGettingPerformance:
        "Se ha producido un error al buscar el rendimiento de sus equipos, compruebe su conexión a Internet y vuelva a cargar la página.",
      errorGettingDecisions:
        "Se ha producido un error al buscar las decisiones de sus equipos, compruebe su conexión a Internet y vuelva a cargar la página.",
      manageTeams: "Administrar Equipos",
      refresh: "Refrescar",
      chooseATeam: "Elegir equipos",
      selectAll: "Seleccionar de todos",
      unselectAll: "Deseleccionar todos",
      noTeamsSelected: "No tienes equipo seleccionado.",
      loadingGraphMarkers: "Dibujando los puntos del gráfico, por favor espere...",
      General: "General",
      Finances: "Finanzas",
      "Standard Line": "Línea Standard",
      "Custom Line": "Línea Custom",
      "Work Force": "Fuerza Laboral",
      "Cash On Hand": "Efectivo en Mano",
      "Inventory Costs": "Costos - Inventario",
      "Salary Costs": "Costos - Salarios",
      "Holding Costs": "Costos - Almacenamiento",
      Inventory: "Inventario",
      "Standard Market Price": "Precio de Mercado Standard",
      "Custom Demand": "Demanda Custom",
      Cash: "Efectivo",
      Debt: "Deuda",
      "Sales Standard": "Ventas Standard",
      "Sales Custom": "Ventas Custom",
      "Accepted Orders": "Órdenes Aceptadas",
      "Accumulated Orders": "Órdenes Acumuladas",
      "Queue 1": "Cola 1",
      "Station 1 Output": "Salida Estación 1 ",
      "Queue 2": "Cola 2",
      "Initial Batching": "Lote Inicial",
      "Queue 3": "Cola 3",
      "Manual Processing": "Procesamiento Manual",
      "Queue 4": "Cola 4",
      "Final Batching": "Lote Final",
      "Queue 5": "Cola 5",
      Deliveries: "Entregas",
      "Queue 2 First Pass": "Primer Pase Cola 2",
      "Station 2 Output": "Salida de la Estación 2",
      "Station 3 Output": "Salida de la Estación 3",
      "Queue 2 Second Pass": "Segundo Pase Cola 2",
      "Actual Price": "Precio Actual",
      "Average Lead Time": "Tiempo de Entrega Promedio",
      Rookies: "Novatos",
      Experts: "Expertos",
      days: "Días",
      "Amount ($)": "Cantidad ($)",
      "Raw Material": "Materia Prima",
      Units: "Unidades",
      Workers: "Empleados",
      day: "Día",
      value: "Valor",
      "There was a problem formatting the data. Please try again or contact support at: support@processimlabs.com":
        "Hubo un problema al formatear los datos. Vuelva a intentarlo o póngase en contacto con el servicio de asistencia en: support@processimlabs.com para obtener asistencia.",
      "No matching simulations found": "No se encontraron simulaciones coincidentes.",
      "There was an error creating the report. Please try again or contact support at: support@processimlabs.com":
        "Se produjo un error al crear el informe. Vuelva a intentarlo o póngase en contacto con el servicio de asistencia en: support@processimlabs.com para obtener asistencia.",
      topThree: "Top 3",
      topFive: "Top 5",
      topTen: "Top 10",
      graphs: "Gráficas",
      performance: "Rendimiento",
      decisions: "Decisiones",
      "Standard Order Frequency": "Frecuencia de pedido",
      "Standard Order Size": "Tamaño de pedido",
      "Standard Station One Buy": "Número de máquinas de la Estación 1",
      "Standard Station One Capacity Allocation": "Asignación de capacidad de la estación 1 a la línea estándar",
      "Standard Initial Batch Size": "Tamaño de lote inicial",
      "Standard Manual Work Day in Shifts": "Jornada de trabajo manual en turnos",
      "Standard Final Batch Size": "Tamaño de lote final",
      "Standard Product Price": "Precio del producto",
      "Custom Station Two Buy": "Número de máquinas de la Estación 2",
      "Custom Station Two Capacity Allocation": "Asignación de capacidad de la estación 2 al primer paso",
      "Custom Station Three Buy": "Número de máquinas de la Estación 3",
      "Reorder Point": "Punto de Reorden",
      "Reorder Quantity": "Cantidad de Reorden",
      "Get Loan": "Préstamos Obtenidos",
      "Pay Loan": "Préstamo Pagados",
      "Desired Number Of Employees": "Número deseado de empleados",
      "Simulation not owned by this user": "La simulación no pertenece a este usuario.",
      noTeamsToDisplayInformation: "Esta simulación aún no tiene equipos para mostrar gráficos de decisiones.",
      loadingDecisionsData: "Cargando datos de decisiones.",
      noTeamSelectedToDisplayInformation: "Selecciona un equipo de la lista de la izquierda.",
    },
    DiscountCodeModal: {
      discountCode: "Código de descuento",
      yourCode: "Su código de descuento...",
      validate: "Validar código",
      "No matching discount code found.": "No se encontró un código de descuento coincidente.",
      discountSuccess: "Su código de descuento ha sido validado con éxito.",
      errorValidatingDiscountCode:
        "Se ha producido un error al validar su código de descuento, verifique su conexión a Internet y vuelva a cargar la página",
      "This discount code already expired.": "Su código de descuento ha expirado.",
      "You exceeded the number of licenses you can create with your discount code.":
        "Ha excedido el número de licencias que puede crear utilizando este código de descuento",
      "Discount code not found.":
        "Este código de descuento está destinado a ser utilizado con un correo electrónico diferente.",
      ValidateDiscountCodeController_DiscountCodeNotFound: "No se encontró un código de descuento coincidente.",
      ValidateDiscountCodeController_DiscountCodeExpired: "Su código de descuento ha expirado.",
      ValidateDiscountCodeController_ExceededNumberOfLicenses:
        "Ha excedido el número de licencias que puede crear utilizando este código de descuento",
      ValidateDiscountCodeController_DiscountCodeDoesntBelong:
        "Este código de descuento está destinado a ser utilizado con un correo electrónico diferente.",
      ValidateDiscountCodeController_EmptySimulationId:
        "¡Oh no!, la simulación no fue incluida en la validacion del código de descuento, por favor intentelo nuevamente. Si el problema persiste, por favor contáctanos utilizando el chat de soporte.",
      ValidateDiscountCodeController_PaymentSummaryNotFound:
        "¡Oh no!, parece que la simulación no fue creada correctamente. Verifica con tu profesor que creó la simulación.",
    },
    DecisionHistory: {
      DecisionHistoryTitle: "Historial de Decisiones",
      loadingDecisionHistory: "Cargando el historial de decisiones...",
      userName: "Nombre",
      description: "Descripción",
      timeSubmitted: "Hora Enviada",
      simulationDay: "Día de Simulación",
      noChangesYet: "Este equipo no ha hecho cambios aún.",
    },
    DownloadApp: {
      youAreGoodToGo: "¡Estas listo para empezar!",
      pleaseDownloadOurMobileApp:
        "Descarga nuestra aplicación de la tienda correspondiente haciendo click en uno de los botones a continuación:",
      sameCredentials:
        "Inicia sesión en la aplicación móvil con el mismo usuario y contraseña que utilizas para nuestro sitio web y comienza a administrar tu propia empresa virtual.",
      userPlayedBefore: `Si tienes la aplicación de Medica Scientific instalada en tu teléfono y has jugado una simulación anteriormente, puedes cambiar entre simulaciones en la sección de configuración de la aplicación utilizando el botón de "Cambiar de Simulación".`,
      orClickHereToDownloadItToYourWindowsComputer:
        "O presiona <1>aquí</1> para descargar la aplicación en tu computadora con Windows.",
    },
    FAQ: {
      title: "Preguntas Frecuentes",
      All: "Todas",
      "Create Simulation": "Crear Simulación",
      "Simulation Details": "Detalles de la Simulación",
      "Student FAQ": "FAQ de Estudiantes",
      "Advance Simulation": "Avanzar Simulación",
      Login: "Iniciar Sesión",
      "Create Account": "Crear Cuenta",
      "Join Team": "Unirse a un equipo",
      Payment: "Pago",
      Gameplay: "¿Como Jugar?",
      "Standard Line": "Línea Standard",
      "Custom Line": "Línea Custom",
      Inventory: "Inventario",
      "Work Force": "Empleados",
      "Activation Code": "Código de Activación",
      "Join Simulation": "Unirse a una Simulación",
      "Supply and Assembly": "Suministro y Montaje",
      "Production 1": "Producción 1",
      "Production 2": "Producción 2",
      "Create Team": "Crear Equipo",
    },
    HomeScreen: {
      students: "Estudiantes",
      addNewSimulation: "Crear nueva simulación",
      startDate: "Fecha de inicio",
      simulations: "Simulaciones",
      errorFetchingSimulations:
        "Se ha producido un error al recuperar las simulaciones, compruebe su conexión a Internet y vuelva a cargar la página.",
      loadingSimulations: "Cargando Simulaciones, por favor espere...",
      "User doesn't have any simulations": "No tienes ninguna simulación registrada.",
      professorManaged: "Manejado por el Profesor",
      pending: "Inicio pendiente",
      noSimulationList:
        "En esta sección verás todas tus simulaciones. Cuando estés listo, puedes presionar el botón de abajo para crear tu primera simulación.",
      "Simulation not found": "No tienes ninguna simulación registrada.",
      at: "a las",
      welcomeToProcessimlabs: "¡Bienvenido a Processim Labs!",
      homeVideoDescription: "Vea este video rápido para comenzar a configurar tu primera simulación.",
      dontShowVideoAgain: "¡No volver a mostrar este vídeo!",
      WelcomeModalGoToVideos: "Ver Videos",
      WelcomeModalTitle: "¡Le damos la bienvenida a su cuenta de Processim Labs!",
      WelcomeModalBody1:
        "Esta es su pantalla de inicio, donde encontrará las simulaciones que ha creado, podrá acceder a ellas y supervisarlas cuando lo necesite.",
      WelcomeModalBody2:
        "Le invitamos a revisar nuestra sección de Videos. Allí encontrará contenido que puede ayudarle a navegar por nuestro sitio web, configurar nuestros juegos de simulación y más.",
      WelcomeModalNeverShowAgain: "No Mostrar de Nuevo",
    },
    JoinTeamModal: {
      ok: "Ok",
      retry: "Reintentar",
      cancel: "Cancelar",
    },
    MedicaDetailScreen: {
      simulationDetails: "Detalles de la simulación",
      initializationPeriod: "Período de inicialización",
      studentInteractionPeriod: "Período de interacción de estudiantes",
      endingPeriod: "Período de finalización",
      activationCode: "Código de Activación",
      startTime: "Hora de inicio",
      templateName: "Escenario seleccionado",
      modify: "Modificar",
      standings: "Posiciones",
      registeredStudents: "Estudiantes Registrados",
      shareSimulation: "Compartir simulación",
      runAgain: "Empezar Siguiente Corrida",
      decisionHistory: "Historial de Decisiones",
      errorFetchDetails:
        "Se ha producido un error al recuperar los detalles de la simulación, compruebe su conexión a Internet y vuelva a cargar la página.",
      virtualDaysPerHour: "Días virtuales por hora",
      medicaVirtualDaysHelp:
        "Esta es la cantidad de días virtuales que la simulación avanzará por hora del mundo real durante el período de interacción del estudiante.",
      virtualDays: "días virtuales",
      medicaStatusHelp:
        "Después de que se haya creado una simulación, tendrá uno de estos estados: Inicializado, En ejecución o Finalizado.",
      medicaInitializationPeriodHelp:
        "Cantidad de días virtuales que ya han transcurrido antes del período de interacción del alumno.",
      medicaStudentInteractionPeriodHelp:
        "Cantidad de días virtuales que los estudiantes deberán gestionar de la empresa.",
      medicaEndOfSimulationPeriodHelp:
        "Cantidad de días que se ejecutarán una vez finalizado el período de interacción.",
      professorManaged: "Manejado por el Profesor",
      downloadAll: "Descargar todo",
      runAgainModalTitle: "Configuración de simulación",
      runAgainModalMessage:
        "Antes de que puedas configurar tu próxima ejecución, tu simulación actual debe estar ejecutándose o ya haber finalizado.",
      documentationCurrentlyBeingCreated:
        "La documentación de simulación se está creando actualmente. Por favor, vuelva en breve.",
      errorFetchingDocuments:
        "Se ha producido un error al recuperar los documentos de simulación. Compruebe su conexión a Internet y vuelva a cargar la página.",
      "Please create a simulation before getting documentation.":
        "Cree una simulación antes de obtener la documentación.",
      "User doesn't own a simulation with that name": "El usuario no posee una simulación con ese nombre.",
      "User doesn't own any simulations": "El usuario no posee ninguna simulación.",
      "User doesn't own this simulation": "El usuario no posee esta simulación.",
      documents: "Documentos",
      at: "a las",
    },
    MedicaBuyQuestionnaire: {
      whoPaysHelp:
        "El costo de las licencias puede ser cubierto por la institución o por cada estudiante a la hora de registrarse.",
    },
    MedicaBadLink: {
      badLink: "Parece que has utilizado el enlace equivocado.",
      tryFollowing: "Intenta alguna de las siguientes opciones:",
      tryingToCreateTeam: "Si deseas crear un equipo, confirma con tu profesor que tienes el enlace correcto.",
      tryingToJoinTeam: "Si deseas unirte a un equipo, confirma el enlace con tu líder de equipo.",
      lastResort:
        "Si ninguna de las anteriores funciona, no dudes en contactarnos en <1>support@processimlabs.com</1> o utilizando el chat en la esquina inferior derecha de esta página.",
    },
    ModifyModal: {
      modify: "Modificar",
      accept: "Aceptar",
      cancel: "Cancelar",
      name: "Nombre",
      startDate: "Fecha de inicio",
      endDate: "Fecha final",
      modifyDatesSuccess: "Las fechas cambiaron con éxito.",
      equalDatesSimulationError: "La simulación tiene las mismas fechas de inicio y finalización seleccionadas.",
      "Dates must be in the future": "Las fechas deben ser en el futuro.",
      "End Date must be after Start Date": "La fecha de finalización debe ser posterior a la fecha de inicio.",
      "Simulation must run for at least 6 hours": "La simulación debe ejecutarse durante al menos 6 horas.",
      "Cannot change Start Time one hour before simulation starts":
        "No se puede cambiar la hora de inicio una hora antes de que comience la simulación.",
      errorPosting:
        "Se ha producido un error al guardar los datos, compruebe su conexión a Internet e inténtelo de nuevo.",
      "Simulation can only run for 7 days or less": "La simulación solo puede ejecutarse durante 7 días o menos.",
      "Bad request": "An error has occurred while sending the request please check try again.",
      "Bad Date format":
        "Se ha producido un error al enviar la solicitud. Por favor, vuelva a intentarlo o contáctenos en: support@processimlabs.com para obtener ayuda.",
      "Couldn't find control team":
        "No se pudo encontrar el equipo de control, intente nuevamente o contáctenos en: support@processimlabs.com para obtener ayuda.",
      "Couldn't find simulation Activation Code":
        "No se pudo encontrar el código de activación de la simulación. intente nuevamente o contáctenos en: support@processimlabs.com para obtener asistencia.",
      simulationMustStartLeastOneHourInFuture: "La simulación debe comenzar al menos una hora en el futuro.",
    },
    Payment: {
      payment: "Finalizar Pedido",
      wouldYouLikeToCompleteThePurchase: "¿Te gustaría completar la compra?",
      purchaseComplete: "Compra Completada",
      creditCardInformation: "Información de tarjeta de crédito",
      cardHolderName: "Nombre del titular de la tarjeta",
      required: "Campo requerido",
      yourPaymentHasBeenSuccessfullyRegistered: "Su pago ha sido registrado exitosamente.",
      paymentSuccess: "Pago exitoso",
      purchasedCompleted: "Compra completada",
      pay: "Pagar",
      discountCode: "Código de descuento",
      removeDiscountCode: "Remover Código de Descuento",
      cancel: "Cancelar",
      retry: "Usar otro código",
      accept: "Aceptar",
      writeValidCode: "Escriba un código válido",
      checkingStudentPayment: "Revisando historial de pagos",
      studentAlreadyPayedMessage: "Su pago ha sido verificado",
      checkPreviousPaymentError: "Ha ocurrido un error al verificar el historial de pagos",
      simulationAlreadyFinished:
        "Esta simulación ya terminó. Por favor confirma con tu profesor que el enlace sea el correcto.",
      "You've already payed for this simulation": "Ya has pagado por esta simulación.",
      errorPostingPayment: "Hubo un error al procesar su pago. Por favor inténtelo de nuevo.",
      expired_card: "La tarjeta ha expirado. Por favor intente con otra.",
      fraudulent: "Su tarjeta fue denegada. Por favor contacte al emisor para más información.",
      generic_decline: "Su tarjeta fue denegada. Por favor contacte al emisor para más información.",
      card_declined: "Su tarjeta fue denegada. Por favor contacte al emisor para más información.",
      insufficient_funds: "Su pago no fue aceptado debido a falta de fondos.",
      transaction_not_allowed:
        "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      incorrect_cvc: "El número CVC es incorrecto. Por favor revise la información de su tarjeta.",
      invalid_cvc: "El número CVC es incorrecto. Por favor revise la información de su tarjeta.",
      card_not_supported: "Su tarjeta no es aceptada por nuestra plataforma de pago. Por favor intente con otra.",
      duplicate_transaction:
        "Una transacción por el mismo monto y con la misma información fue procesada recientemente. Por favor revise si el pago ya existe.",
      do_not_honor: "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      do_not_try_again:
        "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      currency_not_supported: "Su tarjeta no acepta la moneda de pago. Por favor intente con otra.",
      "There was an error please use your link again.":
        "Hubo un error. Por favor vuelva a utilizar el enlace en una pestaña diferente del navegador.",
    },
    PaymentCrisis: {
      payment: "Pago",
      wouldYouLikeToCompleteThePurchase: "¿Te gustaría completar la compra?",
      purchaseComplete: "Compra Completada",
      creditCardInformation: "Información de tarjeta de crédito",
      cardHolderName: "Nombre del titular de la tarjeta",
      required: "Campo requerido",
      yourPaymentHasBeenSuccessfullyRegistered: "Su pago ha sido registrado exitosamente.",
      paymentSuccess: "Pago exitoso",
      purchasedCompleted: "Compra completada",
      pay: "Pagar",
      validate: "Validar código",
      cancel: "Cancelar",
      retry: "Usar otro código",
      accept: "Aceptar",
      writeValidCode: "Escriba un código válido",
      checkingStudentPayment: "Revisando historial de pagos",
      studentAlreadyPayedMessage: "Su pago ha sido verificado",
      checkPreviousPaymentError: "Ha ocurrido un error al verificar el historial de pagos",
      "You've already payed for this simulation": "Ya has pagado por esta simulación.",
      errorPostingPayment: "Hubo un error al procesar su pago. Por favor inténtelo de nuevo.",
      expired_card: "La tarjeta ha expirado. Por favor intente con otra.",
      fraudulent: "Su tarjeta fue denegada. Por favor contacte al emisor para más información.",
      generic_decline: "Su tarjeta fue denegada. Por favor contacte al emisor para más información.",
      card_declined: "Su tarjeta fue denegada. Por favor contacte al emisor para más información.",
      insufficient_funds: "Su pago no fue aceptado debido a falta de fondos.",
      transaction_not_allowed:
        "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      incorrect_cvc: "El número CVC es incorrecto. Por favor revise la información de su tarjeta.",
      invalid_cvc: "El número CVC es incorrecto. Por favor revise la información de su tarjeta.",
      card_not_supported: "Su tarjeta no es aceptada por nuestra plataforma de pago. Por favor intente con otra.",
      duplicate_transaction:
        "Una transacción por el mismo monto y con la misma información fue procesada recientemente. Por favor revise si el pago ya existe.",
      do_not_honor: "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      do_not_try_again:
        "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      currency_not_supported: "Su tarjeta no acepta la moneda de pago. Por favor intente con otra.",
      "There was an error please use your link again.":
        "Hubo un error. Por favor vuelva a utilizar el enlace en una pestaña diferente del navegador.",
      ProcessPaymentController_OnlyProfessorCreateSimulation: "Solo un profesor puede pagar para crear una simulacion.",
      ProcessPaymentController_MissingPaymentSummary:
        "No encontramos la simulación por la que estas pagando, por favor verifica con tu profesor que el creó la simulación.",
      ProcessPaymentController_PaymentFailed:
        "Su pago no se pudo realizar, por favor intentelo nuevamente. Si el problema persiste, por favor contáctanos utilizando el chat de soporte.",
      StudentHasPaidController_SimulationNotFound:
        "¡Oh no!, parece que la simulación a la que te has intentado unir no existe. Por favor confirma con tu profesor que has utilizado el link adecuado.",
      StudentHasPaidController_PaymentSummaryNotFound:
        "¡Oh no!, no encontramos la simulación por la que estas pagando, por favor verifica con tu profesor que el creó la simulación.",
      StudentHasPaidController_StudentHasNotPaid: "Seras redirigido al proceso de pago.",
    },
    PaymentSupplyChain: {
      payment: "Pago",
      wouldYouLikeToCompleteThePurchase: "¿Te gustaría completar la compra?",
      purchaseComplete: "Compra Completada",
      creditCardInformation: "Información de tarjeta de crédito",
      cardHolderName: "Nombre del titular de la tarjeta",
      required: "Campo requerido",
      yourPaymentHasBeenSuccessfullyRegistered: "Su pago ha sido registrado exitosamente.",
      paymentSuccess: "Pago exitoso",
      purchasedCompleted: "Compra completada",
      pay: "Pagar",
      validate: "Validar código",
      cancel: "Cancelar",
      retry: "Usar otro código",
      accept: "Aceptar",
      discountSuccess: "Su código de descuento ha sido validado con éxito.",
      writeValidCode: "Escriba un código válido",
      checkingStudentPayment: "Revisando historial de pagos",
      studentAlreadyPayedMessage: "Su pago ha sido verificado",
      checkPreviousPaymentError: "Ha ocurrido un error al verificar el historial de pagos",
      "You've already payed for this simulation": "Ya has pagado por esta simulación.",
      errorPostingPayment: "Hubo un error al procesar su pago. Por favor inténtelo de nuevo.",
      generic_decline: "Su tarjeta fue denegada.",
      insufficient_funds: "Su pago no fue aceptado debido a falta de fondos.",
      transaction_not_allowed:
        "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      incorrect_cvc: "El número CVC es incorrecto. Por favor revise la información de su tarjeta.",
      invalid_cvc: "El número CVC es incorrecto. Por favor revise la información de su tarjeta.",
      card_not_supported: "Su tarjeta no es aceptada por nuestra plataforma de pago. Por favor intente con otra.",
      duplicate_transaction:
        "Una transacción por el mismo monto y con la misma información fue procesada recientemente. Por favor revise si el pago ya existe.",
      do_not_honor: "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      do_not_try_again:
        "Su tarjeta fue denegada. Por favor contacte al emisor para más información o intente con otra.",
      currency_not_supported: "Su tarjeta no acepta la moneda de pago. Por favor intente con otra.",
      "There was an error please use your link again.":
        "Hubo un error. Por favor vuelva a utilizar el enlace en una pestaña diferente del navegador.",
      ProcessPaymentController_OnlyProfessorCreateSimulation: "Solo un profesor puede pagar para crear una simulacion.",
      ProcessPaymentController_MissingPaymentSummary:
        "No encontramos la simulación por la que estas pagando, por favor verifica con tu profesor que el creó la simulación.",
      ProcessPaymentController_PaymentFailed:
        "Su pago no se pudo realizar, por favor intentelo nuevamente. Si el problema persiste, por favor contáctanos utilizando el chat de soporte.",
      StudentHasPaidController_SimulationNotFound:
        "¡Oh no!, parece que la simulación a la que te has intentado unir no existe. Por favor confirma con tu profesor que has utilizado el link adecuado.",
      StudentHasPaidController_PaymentSummaryNotFound:
        "¡Oh no!, no encontramos la simulación por la que estas pagando, por favor verifica con tu profesor que el creó la simulación.",
      StudentHasPaidController_StudentHasNotPaid: "Seras redirigido al proceso de pago.",
    },
    PaymentForio: {
      payment: "Pago",
      wouldYouLikeToCompleteThePurchase: "¿Te gustaría completar la compra?",
      purchaseComplete: "Compra Completada",
      required: "Campo requerido",
      yourPaymentHasBeenSuccessfullyRegistered: "Tu producto ha sido activado.",
      paymentSuccess: "Activación Completa",
      purchasedCompleted: "Compra completada",
      pay: "Pagar",
      discountCode: "Utilizar Código de Activación",
      removeDiscountCode: "Remover Código de Activación",
      yourCode: "Su código de activación...",
      validate: "Validar código",
      cancel: "Cancelar",
      retry: "Usar otro código",
      accept: "Aceptar",
      ValidateDiscountCodeController_DiscountCodeNotFound: "No se encontró un código de activación coincidente.",
      discountSuccess: "Su código de activación ha sido validado con éxito.",
      errorValidatingDiscountCode:
        "Se ha producido un error al validar su código de activación, verifique su conexión a Internet y vuelva a cargar la página",
      writeValidCode: "Escriba un código válido",
      checkingStudentPayment: "Revisando historial de pagos",
      studentAlreadyPayedMessage: "Su pago ha sido verificado",
      checkPreviousPaymentError: "Ha ocurrido un error al verificar el historial de pagos",
      "You've already payed for this simulation": "Ya has pagado por esta simulación.",
      ValidateDiscountCodeController_DiscountCodeExpired: "Su código de activación ha expirado.",
      ValidateDiscountCodeController_ExceededNumberOfLicenses:
        "Ha excedido el número de licencias que puede crear utilizando este código de activación",
      ValidateDiscountCodeController_DiscountCodeDoesntBelong:
        "Este código de activación está destinado a ser utilizado por otro profesor o estudiante.",
      activationCodeHelpText:
        "Ingrese su Código de Activación a continuación para configurar su simulación. Si desea obtener información sobre esta simulación y cómo obtener un Código de Activación, contáctenos en support@processimlabs.com",
    },
    PaymentVerificationModal: {
      retry: "Reintentar",
      title: "Confirmando su pago...",
      defaultErrorMessage:
        "¡Oh no!, Ha habido un error al confirmar el pago. Por favor asegúrese de tener acceso a internet e intente nuevamente. Si esto no funciona, no dude en contactarnos por medio del chat o enviándonos un correo a support@processimlabs.com.",
    },
    ProfileScreen: {
      profileDetails: "Detalles del perfil",
      language: "Idioma",
      fullName: "Nombre completo",
      email: "Correo electrónico",
      role: "Rol",
      institution: "Institución",
      requestFeature: "¿Cómo podemos mejorar?",
      describeYourFeature: "Describe tu característica...",
      send: "Enviar",
      featureDescription: "Descripción de la característica",
      tryAgain: "Intentar de nuevo",
      pleaseDescribeYourFeature: "Por favor describa su característica.",
      featureRequestedSuccessfully: "Su característica ha sido solicitada satisfactoriamente.",
      errorRequestingFeature:
        "Se ha producido un error al solicitar su característica, compruebe su conexión a Internet y vuelva a cargar la página.",
      product: "Producto",
    },
    Purchased: {
      numbersStudents: "Número de estudiantes",
      course: "Curso",
      whoPaysSelected: "¿Quién pagará?",
      yes: "Sí",
      no: "No",
      Professor: "Profesor",
      Student: "Estudiante",
      Students: "Estudiantes",
      knowsDateSelected: "¿Sabes la fecha de inicio de la simulación?",
      next: "Siguiente",
      notValidNumber: "Debe ser un número",
      startDate: "Fecha de inicio",
      endDate: "Fecha final",
      simulationName: "Nombre de la simulación",
      notValidNumberMin: "El número de estudiantes debe ser mínimo de 4.",
      notEnoughStudentErrorSimunation: "El número de estudiantes debe ser mínimo de 1",
      required: "Campo requerido",
      pleaseChooseOne: "Por favor elige uno",
      setDates: "Por favor, fije la fecha",
      notSimulationName: "Por favor dale un nombre a tu simulación.",
      continue: "Continuar",
      creatingSimulation: "Creando simulación",
      simulationAddedSuccessfully: "La simulación ha sido creada satisfactoriamente",
      "Failed to initialize simulation. Please contact support.":
        "Falló la creación de la simulación. Por favor, contacte a soporte.",
      "Simulation name has already been registered": "El nombre de la simulación ya sido utilizado.",
      errorCreatingSimulation:
        "Se ha producido un error al crear tu simulación, comprueba tu conexión a Internet y vuelve a cargar la página",
      verifyFieldsCorrectlyFilled: "Por favor verifique que todos los campos estén llenos de forma correcta.",
      professorAdvanceSimulation: "¿Gestionar el progreso de la simulación manualmente?",
      simunationWhoAdvancesSimulation: "¿Quién controlará el progreso de la simulación?",
      simunationOptionProfessor: "Profesor",
      simunationOptionStudents: "Estudiantes",
      medicaProfessorAdvanceSimulationHelp:
        'Si eliges "No", la simulación avanzará automáticamente cada hora durante el período de tiempo que elijas. Si eliges "Sí", avanzará manualmente la simulación usted mismo',
      simunationProfessorAdvanceSimulationHelp:
        'Si seleccionas "Estudiantes" cada equipo decidirá cuando avanzar su propia simulación. Al seleccionar "Profesor" tu decidirás cuando avanzarán todos los equipos simultáneamente',
      daysToPlay: "Días de interacción del estudiante",
      daysToPlayHelp:
        "Recomendamos 100 días virtuales para clases regulares (2 a 3 horas), mientras que se recomiendan 200 días virtuales para una sesión de día completo. Nuestra sugerencia es usar 300 días virtuales cuando se usa la simulación para varios días del mundo real.",
      errorGettingScenarios: "Los escenarios a la medida no están disponibles en este momento.",
      advancedOptionsButtonText: "Opciones Avanzadas",
      secondRunWarningMessage:
        "La simulación que está a punto de configurar tendrá la misma composición de estudiantes y equipo que la actual. Si todavía hay estudiantes que deben registrarse en la corrida anterior, espere hasta que lo hagan.",
      secondRunParagraphPartOne:
        "Configure su próxima ejecución de Medica Scientific. Puede configurarlo como más le convenga a continuación.",
      secondRunParagraphPartTwo:
        "Tenga en cuenta que la simulación que está a punto de configurar tendrá la misma composición de estudiantes y equipo que la actual. Si todavía hay estudiantes que deben registrarse en la corrida anterior, espere hasta que lo hagan.",
      invalidSimulationNameCharacterError: `Los caracteres válidos incluyen: A-z, 0-9, '_' y '-'`,
      "Simulation must start at least 1 hour in the future from now":
        "Simulación debe comenzar al menos 1 hora en el futuro a partir de ahora.",
      "Total student interaction days can't be less than 100 days":
        "El total de días de interacción del estudiante no puede ser inferior a 100 días.",
      "Total student interaction days can't be more than 300 days":
        "El total de días de interacción del estudiante no puede ser más de 300 días",
      "Your account is setup as a student's account. Please contact the developers.":
        "Su cuenta está configurada como la cuenta de un estudiante. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      "There has been an authentication error, please contact us to get assistance.":
        "Ha habido un error de autenticación. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      "Please add a simulation name": "Por favor, dale un nombre a tu simulación.",
      "End Date must be after Start Date": "La fecha de finalización debe ser posterior a la fecha de inicio.",
      "Simulation must run for at least 6 hours": "La simulación debe ejecutarse durante al menos 6 horas.",
      "Simulation can only run for 7 days or less": "La simulación solo puede ejecutarse durante 7 días o menos.",
      "A simulation with this name already exists in our database. We suggest using a more specific name.":
        "Ya existe una simulación con este nombre en nuestra base de datos. Sugerimos usar un nombre más específico.",
      "Template not available. Please contact us at support@processimlabs.com.":
        "La simulación no se pudo crear. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      "Unable to create a simulation at this time. Error 421":
        "No se puede crear una simulación en este momento. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      "Failed to initialize simulation. Please contact us at support@processimlabs.com":
        "Error al inicializar la simulación. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
    },
    PushNotificationsScreen: {
      title: "Título",
      message: "Mensaje",
      sendNotification: "Enviar notificación",
      allTeams: "Todos los equipos",
      selectTeam: "Seleccione un equipo",
      pushNotificationSuccess: "La notificación push ha sido enviada exitosamente.",
      errorPushing:
        "An error has occurred while sending the notification, please check your Internet connection and try again.",
      noTeamsList: "Esta simulación no tiene equipos creados.",
      errorFetchingTeams:
        "Se ha producido un error al buscar los equipos de la simulación, comprobar su conexión a Internet y volver a cargar la página.",
      verifyFieldsCorrectlyFilled: "Por favor verifique que todos los campos estén llenos de forma correcta.",
      required: "Campo requerido",
      "Simulation not found for current user": "Simulación no encontrada para el usuario actual.",
      "Simulation not owned by this user": "La simulación no pertenece a este usuario.",
      pushNotificationHelpMessage:
        "Envía una notificación a uno o todos los equipos participantes directamente a sus teléfonos móviles.",
      suggestionsSubTitle1:
        "Estos son algunos de los problemas que cada uno de los equipos está teniendo en este momento. Puede usar esta información para ayudar a sus estudiantes a identificar y abordar estos problemas si lo desea. Al hacer clic en cualquiera de las sugerencias, se copiará automáticamente el texto a la sección de Notificaciones Push de la izquierda para que pueda enviar el mensaje fácilmente.",
      suggestionsSubTitle2:
        '*Estos son los mismos mensajes que sus alumnos reciben si usted habilitó las "Sugerencias en la aplicación" al configurar su simulación.',
    },
    RegisteredStudentsScreen: {
      registeredStudentsOf: "Estudiantes Registrados",
      userName: "Nombre de usuario",
      name: "Nombre",
      email: "Email",
      students: "Estudiantes",
      loadingRegisteredStudents: "Cargando estudiantes registrados, por favor espere...",
      notStudentList:
        "No hay estudiantes registrados hasta el momento. Podrás monitorear las inscripciones de estudiantes desde esta sección.",
      errorFetchStudents:
        "Se ha producido un error al obtener Estudiantes registrados de la simulación, compruebe su conexión a Internet y vuelva a cargar la página.",
      "User does not have this simulation": "El usuario no tiene esta simulación.",
    },
    MedicaAdvancedOptions: {
      advancedOptionsTitle: "Opciones Avanzadas",
      advancedOptionsDescription1:
        "Elija el escenario que quiere jueguen sus estudiantes. Estos escenarios difieren en el comportamiento de los precios de mercado, demandas, productividad y precio de las máquinas entre otras variables. Si tiene un conjunto específico de condiciones que desea que enfrenten sus estudiantes, contáctenos en: support@processimlabs.com y haremos lo posible para crearle ese escenario.",
      advancedOptionsDescription2:
        "Además, puede agregar pequeñas variaciones al escenario que eligió en caso que desee que el escenario sea ligeramente distinto, pero no de forma significativa, a la última vez que lo utilizó.",
      recommended: "RECOMENDADO",
      easyDifficultyTemplateName: "Escenario fácil",
      easyDifficultyTemplateDescription:
        "Este escenario está diseñado para estudiantes de pregrado que no tienen mucha experiencia utilizando simulaciones.",
      mediumDifficultyTemplate: "Escenario medio",
      mediumDifficultyTemplateDescription:
        "Este escenario esta recomendado para la mayoría de los casos. Puede ser usado por estudiantes de pregrado y grado.",
      hardDifficultyTemplate: "Escenario difícil",
      hardDifficultyTemplateDescription:
        "Solo estudiantes avanzados con mucha experiencia utilizando simuladores deberían de usar este escenario.",
      customOnlyDifficultyTemplate: "Escenario Introductorio - Solamente línea Custom",
      customOnlyTemplateDescription:
        "Para este escenario introductorio, el estudiante debe únicamente trabajar con la Línea Custom de la planta de producción",
      loadingScenarioComparison: "Cargando comparación de escenarios, por favor espere...",
      errorFetchScenarioInformation:
        "Se ha producido un error al obtener información de escenarios de la simulación, compruebe su conexión a Internet y vuelva a cargar la página.",
      "User does not have this simulation": "El usuario no tiene esta simulación.",
      scenarioComparison: "Comparación de escenarios",
      ATemplate: "Fácil",
      BTemplate: "Medio",
      CTemplate: "Difícil",
      DTemplate: "Introductorio",
      StandardLine: "Línea Standard",
      CustomLine: "Linea Custom",
      Inventory: "Inventario",
      InventoryUnitsPerOrder: "Partes de inventario por unidad",
      StandardCostPerOrder: "Costo fijo por orden",
      StandardStationOneCapacity: "Estación 1 - Capacidad de la máquina (unidades/día)",
      HoldingCost: "Costo de almacenaje por unidad",
      RookieSalary: "Salario novato",
      ExpertSalary: "Salario experto",
      ExpertProductivity: "Capacidad experto (unidades/día)",
      StandardMarketPrice: "Precio de mercado",
      PriceVsDemand: "Demanda (Interpolación líneal)  (razón de precios = precio del producto / precio de mercado)",
      CustomDemand: "Demanda de Línea Custom",
      Distribution: "Distribución: ",
      LogNormalDistribution: "Normal",
      TriangularDistribution: "Triangular",
      WIPLimit: "Límite de WIP",
      CustomStationTwoCapacity: "Estación 2 - Capacidad de la máquina (unidades/día)",
      CustomStationThreeCapacity: "Estación 3 - Capacidad de la máquina (unidades/día)",
      CustomProductPrice: "Precio del producto (Interpolación lineal)",
      InventoryOrderCost: "Costo fijo por orden",
      InventoryUnitCost: "Costo por parte",
      InventoryDeliveryTime: "Tiempo de entrega",
      Days: "día",
      Days_plural: "días",
      Units: "unidad",
      Units_plural: "unidades",
      Parts: "parte",
      Parts_plural: "partes",
      DollarsPerDay: "{{dollarValue}}/día",
      UnitsPerDay: "{{value}} unidad/día",
      UnitsPerDay_plural: "{{value}} unidades/day",
      Orders: "pedido",
      Orders_plural: "pedidos",
      lowerBound: "Límite inferior: ",
      upperBound: "Límite superior: ",
      mode: "Modo: ",
      mean: "Media: ",
      stdDev: "Desviación Estándar: ",
      graphBelow: "Gráfico a continuación",
      playerConfigurable: "Decisiones del jugador (valores iniciales)",
      StandardOrderQuantity: "Cantidad de pedido",
      StandardOrderFrequency: "Frecuencia de pedido",
      StationOneCapacityAllocation: "Asignación de capacidad de la estación uno",
      StandardProductPrice: "Precio del producto",
      StationTwoAllocation: "Asignación de la estación dos",
      InventoryReorderPoint: "Punto de pedido",
      InventoryReorderQuantity: "Cantidad de pedido",
      WorkForce: "Mano de obra",
      NumberOfEmployees: "Número de empleados",
      Employees: "empleado",
      Employees_plural: "empleados",
      unitsIfPriceRatio: "{{ units }}  unidades si la razón de precios es  {{ ratio }}",
      addVariationInformationModalTitle: "¡Nuevas funcionalidades para Medica Scientific!",
      addVariationInformationModalSubtitle: "Introduzca pequeñas variaciones a sus escenarios.",
      addVariationInformationModalBody1:
        "Ahora puede optar por agregar pequeñas variaciones al escenario seleccionado. Esto modificará ligeramente algunas variables dentro de la simulación, como el Precio de Mercado Standard, la Demanda Custom y la capacidad de maquinas y empleados, pero no afectara su comportamiento general. En otras palabras, solo estamos aleatorizando el componente de ruido sobre las formas de onda principales.",
      addVariationInformationModalBody2:
        "Estás dos nuevas funciones están disponibles en la sección Opciones avanzadas.",
      addVariationInformationModalBody3: "Estás nueva función está disponibles en la sección Opciones avanzadas.",
      neverShowThisAgain: "No volver a mostrar",
      selectYourScenario: "Selecciona el escenario: ",
      addVariation: "Añadir variación.",
      addSuggestions: "Habilitar sugerencias en la aplicación:",
      addSuggestionsExplanation:
        "Nuestra aplicación móvil puede dar retroalimentación a sus estudiantes mientras juegan la simulación. Estas sugerencias les ayudarán a darse cuenta cuando ha surgido un problema en su empresa virtual. Si usted elige habilitar las sugerencias, nuestra aplicación notificará esporádicamente a sus estudiantes si su empresa tiene problemas como desabastecimiento de inventario, cuellos de botella, alta rotación de empleados, demasiados pedidos acumulados, altos tiempos de entrega en la línea Custom, baja utilización de empleados o si esta vendiendo sus productos Standard demasiado baratos.",
      addSuggestionsInformationModalSubtitle: "Sugerencias en la aplicación",
      addSuggestionsInformationModalBody:
        "Nuestra aplicación móvil ahora puede notificar a sus estudiantes cuando surjan problemas durante su ejercicio de simulación. Si elige habilitar las sugerencias, nuestra aplicación notificará esporádicamente a sus estudiantes si su empresa tiene problemas como desabastecimiento de inventario, cuellos de botella, alta rotación de empleados, etc.",
      newFeaturesInRegularAndEasyScenarioSubtitle: "Costos de Almacenamiento en el Escenario Medio",
      newFeaturesInRegularAndEasyScenarioBody:
        "Nuestro Escenario Medio ahora incluye costos de almacenamiento en todas las colas e inventario. Las unidades almacenadas en estos lugares tendrán asociado un costo fijo por cada día que pasen almacenadas. Esto debería desincentivar a los estudiantes de acumular unidades en sus líneas de producción o tener políticas de gestión de inventario deficientes.",
    },
    ScenarioManagerScreen: {
      scenarioManager: "Gestor de escenarios",
      loadingProfessorScenarioList: "Cargando su lista de escenarios, por favor espere...",
      noProfessorScenarioList:
        "Puedes crear nuevos escenarios para Medica Scientific. Verás los nuevos escenarios que crees en esta área.",
      errorFetchProfessorScenarioList:
        "Se produjo un error al buscar su lista de escenarios, verifique su conexión a Internet y vuelva a cargar la página.",
      scenarioManagerIntro:
        "Bienvenido al Gestor de Escenarios. Aquí puedes crear escenarios personalizados para tus simulaciones. Esta herramienta esta disponible únicamente para Medica Scientific.",
      scenarioName: "Nombre del Escenario",
      createdAt: "Creado en",
      lastUsedDate: "Última vez utilizado",
      timesUsed: "Utilizaciones",
      edit: "Editar",
      delete: "Borrar",
      close: "Cerrar",
      createScenario: "Crear nuevo escenario",
      deleteScenario: "Borrar escenario",
      scenarioDeletedSuccesfully: "Su escenario ha sido eliminado exitosamente.",
      errorDeletingScenario: "Se ha producido un error al eliminar su escenario, intente nuevamente.",
      errorFetchProfessorDeleteScenario:
        "Se ha producido un error al eliminar su escenario, compruebe su conexión a Internet y vuelva a cargar la página.",
      areYouSureYouWantDeleteScenario: "¿Estas seguro de que deseas eliminar tu escenario <1>{{scenarioName}}</1>?",
    },
    ShareModal: {
      title: "Compartir simulación",
      share:
        "Comparte este enlace con los líderes de equipo en tu clase. Te hemos enviado un correo electrónico con información adicional e instrucciones para que las reenvíes a tus estudiantes sobre cómo comenzar.",
      shareStudent:
        "Comparte este enlace con los compañeros de clase a los que deseas unir a tu equipo. Hemos enviado información adicional a tu correo electrónico.",
      copy: "Copiar al Portapapeles",
      cancel: "Cancelar",
      copySuccess: "El enlace de tu simulación ha sido copiado.",
      shareActivationCode: "Usa este código de activación para compartir tu simulación.",
    },
    SimunationDetailScreen: {
      simulationDetails: "Detalles de la simulación",
      activationCode: "Código de Activación",
      standings: "Posiciones",
      registeredStudents: "Estudiantes Registrados",
      shareSimulation: "Compartir simulación",
      errorFetchDetails:
        "Se ha producido un error al recuperar los detalles de la simulación, compruebe su conexión a Internet y vuelva a cargar la página.",
      "Simulation not found": "Simulación no encontrada.",
      userAdvanceConfirmation: "¿Estás seguro de que quieres avanzar la simulación 5 años?",
      advancingSimulacion: "Avanzando simulación",
      simulationAdvancedSuccessfully: "Su simulación ha sido avanzada con éxito 5 años.",
      simunationCurrentDayHelp: "Este es el año actual del equipo que más ha avanzado en la simulación.",
    },
    StandingsScreen: {
      standings: "Posiciones",
      cash: "Efectivo",
      teamCode: "Código del equipo",
      loadingStandings: "Cargando Posiciones, por favor espere...",
      notStandingsList: "Esta simulación no tiene posiciones.",
      errorFetchStandings:
        "Se ha producido un error al obtener las posiciones de la simulación, compruebe su conexión a Internet y vuelva a cargar la página.",
      score: "Puntuación",
      gdp: "PIB",
      year: "Año",
      standingsHelpMessage: "Los equipos se clasifican según el saldo de efectivo de su empresa.",
      standigsSimunationHelpMessage:
        "La puntuación de un equipo se calcula en función de su rendimiento relativo con respecto a otros equipos en las 3 áreas: SPI, PIB y efectivo.",
      totalTeams: "Total de equipos",
      "Simulation not owned by this user": "La simulación no pertenece a este usuario.",
      shareModalInformation: "Utiliza este enlace para compartir la tabla de posiciones con el mundo.",
      shareModalCopy: "Copiar",
      shareModalCopySuccess: "El enlace ha sido copiado",
      share: "Compartir",
      firstName: "Nombre",
      lastName: "Apellido",
      email: "Correo",
      joinTeamLink: "Link del Equipo",
    },
    StudentCreateTeam: {
      createTeamDescription: "Elige un nombre para tu equipo.",
      teamName: "Nombre del equipo",
      "Team already exists.": "El nombre del equipo ya existe. Por favor prueba con nombre de equipo diferente.",
      createTeam: "Crear equipo",
      creatingTeam: "Creando equipo",
      createTeamSuccess: "¡Equipo creado satisfactoriamente!",
      play: "¡Empezar a jugar!",
      sureYouWantCreateTeam: "¿Estás seguro de que quieres crear el equipo",
      welcomeToProcessimLabs: "Bienvenido a Processim Labs",
      youAreAboutToCreateATeam: "Estas a punto de crear un equipo para nuestra simulación Medica Scientific.",
      wereYouAssignedAsATeamLeader: "¿Te asignaron como líder de equipo? (Solo puede haber uno por equipo).",
      "You can only create one team.":
        "Solo debes crear un equipo por simulación. Ahora te redireccionaremos a nuestras preguntas frecuentes en caso de que tengas preguntas adicionales.",
      errorCreatingTeam:
        "Se ha producido un error intentar crear su equipo, compruebe su conexión a Internet y vuelva a cargar la página.",
      "Simulation not found":
        "No se encontró la simulación, confirme que el enlace proporcionado por su profesor es el correcto.",
      "Simulation missing. Check code.":
        "No se encontró la simulación, confirme que el enlace proporcionado por su profesor es el correcto.",
      "There has been an authentication error, please contact us to get assistance.":
        "Ha habido un error de autenticación. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      "Server is currently busy please try again.": "El servidor está ocupado actualmente, intente nuevamente.",
      "Invalid activation code.":
        "Código de activación inválido. Confirme que el enlace proporcionado por su profesor es el correcto.",
      "Max number of registrations exceeded. Please contact your professor.":
        "Se ha excedido el número máximo de registros. Por favor contacte a su profesor.",
      "Activation code has already expired. Please contact your professor.":
        "El código de activación ya ha expirado. Por favor contacte a su profesor.",
      "Unable to create team at this time. Error 422":
        "No se puede crear un equipo en este momento. Póngase en contacto con nosotros en support@processimlabs.com para obtener asistencia.",
      invalidTeamNameCharacterError: `Los caracteres válidos incluyen: A-z, 0-9, '_' y '-'`,
      retry: "Reintentar",
    },
    StudentInstructionsModal: {
      teamLeaderInstructions: "Instrucciones para el líder del equipo",
      studentInstructions: "Instrucciones para miembros del equipo",
      leaderIntroduction: "¡Increíble! Esto es lo que debes hacer ahora:",
      noLeaderIntroduction: "En ese caso, esto es lo que debe hacer:",
      afterDismissingThisPopUpYouWillNeedCreateOrLogin:
        "Después de cerrar esta ventana emergente, deberás crear o iniciar sesión con tu cuenta con nosotros.",
      thenEnterTheNameOfYourChoiseForYourTeam: "Ingresa el nombre que elegiste para tu equipo.",
      youWillBePresentedWithAUniqueLink:
        "Se te presentará un enlace único. Compártelo con tus compañeros de equipo para que se unan a ti. Este enlace es privado y no debe compartirse fuera de tu equipo.",
      downloadOurMobileAppAndLogIn:
        "Descarga nuestra aplicación móvil e inicia sesión con el mismo nombre de usuario y contraseña que utilizaste en nuestro sitio web",
      enjoyManagingYourVirtualCompany: "¡Disfruta administrando tu empresa virtual!",
      waitForYourTeamLeadToShareTheTeamsUniqueLinkWithYou:
        "Espera a que el líder de tu equipo comparta el enlace único del equipo contigo.",
      clickOnTheLinkToSetUpYourAccountOnOurWebsite:
        "Haz clic en el enlace para configurar tu cuenta en nuestro sitio web.",
      youCanNowDownloadTheMobileAppOnYourSmartphone:
        "Luego podrás descargar la aplicación móvil en tu teléfono inteligente. Se accede a la aplicación móvil con el mismo nombre de usuario y contraseña que utilizaste para configurar tu cuenta en nuestro sitio web.",
    },
    StudentJoinTeamScreen: {
      joiningTeam: "Uniéndose al equipo...",
      joinTeamSuccess: "Te has unido al equipo:",
      errorJoiningTeam: "Ha ocurrido un error. Intentalo de nuevo.",
      "User already registered in this team":
        "Ya eres parte de este equipo y puedes comenzar a usar la simulación desde nuestra aplicación móvil. Ahora lo redireccionaremos a nuestras preguntas frecuentes en caso de que tengas preguntas adicionales.",
      "Simulation missing. Check code.": "Simulación no encontrada, por favor verifique su código de equipo.",
      sureYouWantJoinTeam: "¿Estás seguro de que quieres unirte al equipo",
      noTeamMessageError:
        "No hay Nombre del equipo, intente ingresar nuevamente con el enlace proporcionado por su Líder de equipo o su Profesor.",
      noJoinTeamTeamCode:
        "Algo no ha salido bien a la hora de unirte al equipo, por favor intenta utilizando nuevamente el enlace para unirte al equipo que te ha dado tu colega, si esto sucede nuevamente no dudes en contactarnos en support@processimlabs.com.",
    },
    StudentsLoginScreen: {
      itSeemsYouAreNotInTheRightPlace:
        "Parece que no estás en el lugar correcto. Lee las siguientes preguntas frecuentes para encontrar lo que debes hacer a continuación.",
      areYouTryingToJoinSimulationQuestion: "¿Estás intentando unirte a una simulación?",
      areYouTryingToJoinSimulationResponse:
        "Tu profesor o líder de equipo debe proporcionarte un enlace para comenzar.",
      haveYouAlreadyJoinedATeamQuestion:
        "¿Ya te uniste a un equipo a través de nuestro sitio web pero no sabes qué hacer a continuación?",
      haveYouAlreadyJoinedATeamResponse:
        "Debes descargar nuestra aplicación, Medica Scientific, de la tienda de Android o Apple e iniciar sesión con el mismo nombre de usuario y contraseña que utilizaste para acceder a nuestro sitio web.",
      doYouWantToChangeTeamNameQuestion: "¿Quieres cambiar el nombre de un equipo existente?",
      doYouWantToChangeTeamNameResponse: "Desafortunadamente, tendrás que jugar con el nombre seleccionado.",
      areYouTryingToCreateSecondTeamQuestion: "¿Estás tratando de crear un segundo equipo?",
      areYouTryingToCreateSecondTeamResponse: "Cada líder de equipo solo puede crear un equipo.",
      doYouWantToSwitchTeamsQuestion: "¿Quieres cambiar de equipo?",
      doYouWantToSwitchTeamsResponse:
        "Simplemente debes solicitar al líder el enlace único del equipo y lo trasladarán al nuevo equipo. Ten en cuenta que solo puedes ser asignado a un equipo a la vez.",
      canIBePartOfTwoTeamsQuestion: "¿Puedo formar parte de dos equipos en la misma simulación?",
      canIBePartOfTwoTeamsResponse: "No, solo puedes ser asignado a un equipo a la vez.",
      canIAccessTheAppBeforeSimulationStartsQuestion:
        "¿Puedo acceder a la aplicación antes de que comience la simulación?",
      canIAccessTheAppBeforeSimulationStartsResponse:
        "Sí, puedes acceder a la aplicación, pero no podrás realizar ningún cambio hasta que comience la simulación.",
      howDoIKnowSimulationStartsEndsQuestion: "¿Cómo sé cuándo comienza / termina mi simulación?",
      howDoIKnowSimulationStartsEndsResponse: "Tu profesor debe proporcionarte esta información.",
      contactUs:
        "Si todavía tienes preguntas, puedes comunicarte con nosotros a través del chat en la parte inferior derecha o escribiendo a ",
      supportEmail: "support@processimlabs.com.",
      howDoISwitchFromAPreviousSimulationQuestion: "¿Cómo cambio de una simulación anterior a una nueva?",
      howDoISwitchFromAPreviousSimulationResponse: `Después de que tu profesor te haga saber que él o ella ya han creado la nueva simulación, en la aplicación móvil de Medica Scientific ve a Configuración y toca "Cambiar simulación".`,
      FAQ: "Preguntas más frecuentes",
    },
    SupplyChainAdvancedOptions: {
      selectYourScenario: "Selecciona el escenario: ",
      defaultScenarioTitle: "Escenario por defecto",
      defaultScenarioDescription: "Este escenario se recomienda para los usuarios primerizos.",
    },
    SupplyChainAdvanceSimulationModal: {
      StartSimulationController_SimulationEmpty:
        "No hay estudiantes registrados en esta simulación. Por favor asegúrate de que los estudiantes se hayan registrado para poder iniciar la simulación.",
      StartSimulationController_MoreTeamThanStudents:
        "Por favor escribe un número de equipos que sea menor que la cantidad total de estudiantes registrados.",
      StartSimulationController_InsufficientTeams:
        "Por favor escoge un número de equipos más grande. La cantidad máxima de estudiantes en un mismo equipo debe ser igual o menor que 4.",
      StartSimulationController_FailedStartTeam:
        "Ha fallado el proceso de inicializar la simulación. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      StartSimulationController_SimulationNotFound:
        "No hemos podido encontrar la simulación que intentas comenzar. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      AdvanceSimulationController_SimulatinNotFound:
        "No hemos podido encontrar la simulación que intentas avanzar. Por favor inténtalo nuevamente. Si esto vuelve a suceder, por favor contáctanos utilizando el chat de soporte.",
      AdvanceSimulationController_SimulationFinished: "La simulación que intentas avanzar ya ha finalizado.",
      AdvanceSimulationController_FailedAdvanceSimulation:
        "No hemos podido avanzar la simulación, por favor inténtalo nuevamente en unos minutos.",
      AdvanceSimulationController_SimulationDoesntBelong: "Parece que la simulación es usada por otro profesor.",
      AdvanceSimulationController_StartSimulation:
        "La simulación que intentas avanzar no ha comenzado. Por favor comienza la simulación primero.",
      NumberOfTeams: "Cantidad de equipos",
      NumberOfWeeks: "Número de semanas",
      StudentsAreJoined:
        "Por favor asegúrate de que todos tus estudiantes se hayan unido a la simulación antes de comenzar.",
      RegisteredStudents: "Estudiantes registrados:",
      RecommendedNumberOfTeams: "Número recomendado de equipos:",
      RoundedUp: "N/4 redondeado hacia arriba.",
      MissingSpotsMessage: "Las posiciones faltantes serán jugadas por bots.",
    },
    SupplyChainBuySimulation: {
      creatingSimulation: "Creando Simulación...",
      numberOfStudentsError: "El número de estudiantes debe ser al menos 4.",
      numberOfStudents: "Número de estudiantes",
      CreateSimulationController_MissingPayment:
        "No hemos podido verificar tu pago, por favor contáctanos utilizando el chat.",
      SimulationNameInUse:
        "Oops, ese nombre de la simulación ya se ha utilizado, te sugerimos utilizar un nombre de simulación mas específico",
      SimulationNameTooLong:
        "El nombre de la simulación es muy largo, por favor intenta con un nombre más corto de máximo 25 caracteres.",
      whoPaysHelp:
        "El costo de las licencias puede ser cubierto por la institución o por cada estudiante a la hora de registrarse.",
      whoPaysSelected: "¿Quién pagará?",
      pleaseChooseOne: "Por favor elige uno",
      verifyFieldsCorrectlyFilled: "Por favor verifique que todos los campos estén llenos de forma correcta.",
    },
    SupplyChainCommon: {
      OneStationUnknownPeers: "Una sección - compañeros desconocidos",
      OneStationKnownPeers: "Una sección - compañeros conocidos",
      AllStationsKnownPeers: "Todas las secciones - compañeros conocidos",
      Decision_RiceSupplier: "Suplidor De Arroz",
      Decision_RiceReorderQuantity: "Tamaño De Orden De Arroz",
      Decision_RiceReorderPoint: "Punto De Reorden de Arroz",
      Decision_AgaveReorderQuantity: "Tamaño De Orden De Agave",
      Decision_RicelyOrder: "Tamaño De Orden De Ricely",
      Decision_KrispsOrder: "Tamaño De Orden De Krisps",
      Decision_RicelyOrderSize: "Orden De Ricely",
      Decision_KrispsOrderSize: "Orden De Krisps",
      Decision_MachinesRicely: "Maquinas Deseadas Ricely",
      Decision_MachinesKrisps: "Maquinas Deseadas Krisps",
      Decision_RicelyEmployees: "Empleados Deseados Ricely",
      Decision_KrispsEmployees: "Empleados Deseados Krisps",
      Decision_RicelyShiftLength: "Duración Del Turno Ricely",
      Decision_KrispsShiftLength: "Duración Del Turno Krisps",
      Decision_LMRicelyReorderQuantity: "Tamaño De Orden De Ricely",
      Decision_LMRicelyReorderPoint: "Punto De Reorden de Ricely",
      Decision_LMKrispsOrderSize: "Tamaño De Orden De Krisps",
      Decision_AuroraTransportMethod: "Aurora - Método De Transporte",
      Decision_AuroraDispatcher: "Aurora - Despachador",
      Decision_BarcelonaOrderUpToSize: "Barcelona - Pedido Hasta",
      Decision_BarcelonaWarehouseState: "Barcelona - Estado Del Almacén",
      Decision_BarcelonaSupplier: "Barcelona - Suplidor",
      Decision_BarcelonaTransportMethod: "Barcelona - Método De Transporte",
      Decision_BarcelonaDispatcher: "Barcelona - Despachador",
      Decision_GenevaOrderUpToSize: "Geneva - Pedido Hasta",
      Decision_GenevaWarehouseState: "Geneva - Estado Del Almacén",
      Decision_GenevaSupplier: "Geneva - Suplidor",
      Decision_GenevaTransportMethod: "Geneva - Método De Transporte",
      Decision_GenevaDispatcher: "Geneva - Despachador",
      Decision_AlamedaWarehouseState: "Alameda - Estado Del Almacén",
      Decision_AlamedaSupplier: "Alameda - Suplidor",
      Decision_AlamedaProductPrice: "Alameda - Precio Del Producto",
      Decision_AlamedaTransportMethod: "Alameda - Método De Transporte",
      Decision_BelmontOrderSize: "Belmont - Tamaño De Orden",
      Decision_BelmontWarehouseState: "Belmont - Estado Del Almacén",
      Decision_BelmontSupplier: "Belmont - Suplidor",
      Decision_BelmontProductPrice: "Belmont - Precio Del Producto",
      Decision_BelmontTransportMethod: "Belmont - Método De Transporte",
      Decision_GrantOrderSize: "Grant - Tamaño De Orden",
      Decision_GrantWarehouseState: "Grant - Estado Del Almacén",
      Decision_GrantSupplier: "Grant - Suplidor",
      Decision_GrantProductPrice: "Grant - Precio Del Producto",
      Decision_GrantTransportMethod: "Grant - Método De Transporte",
      SupplyAndAssembly: "Suministro y Montaje",
      Production: "Producción",
      NetworkA: "Augusta",
      NetworkB: "Buenavista",
      LastMile: "Última Milla",
      GameMode: "Modo de Juego",
      GameModeHelp:
        "Con la selección de modo de juego podrá seleccionar como quiere que sus estudiantes interactúen con la simulación y entre ellos.",
      OneStationUnknownPeersHelp:
        "Una Sección - Compañeros Desconocidos: cada estudiante administrará solamente una sección de la cadena de suministro, pero no sabrán cuales compañeros operan las otras secciones.",
      OneStationKnownPeersHelp:
        "Una Sección - Compañeros Conocidos: cada estudiante administrará solamente una sección de la cadena de suministro y además sabrán a cuál equipo pertenecen.",
      AllStationsKnownPeersHelp:
        "Todas las secciones - Compañeros Conocidos: Cada estudiante tendra acceso a todas las secciones de la cadena de suministro, y además sabrán a cuál equipo pertenecen.",
      LastMileHelp:
        "Última Milla: Hasta 2 estudiantes por equipo administrarán 2 regiones comerciales distintas, su logística y canales de distribución.",
      SimulationPerformanceCatalogue_AccumulatedOrders: "Órdenes Acumuladas",
      SimulationPerformanceCatalogue_SupplyAndAssemblyInventory: "Suministro y Montaje - Inventario",
      SimulationPerformanceCatalogue_MaterialX: "Arroz",
      SimulationPerformanceCatalogue_MaterialY: "Agave",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductA: "Suministro y Montaje - Ricely",
      SimulationPerformanceCatalogue_SupplyAndAssemblyProductB: "Suministro y Montaje - Krisps",
      SimulationPerformanceCatalogue_Production: "Producción",
      SimulationPerformanceCatalogue_FinishedGoods: "Producto Terminado",
      SimulationPerformanceCatalogue_ProductionProductA: "Producción - Ricely",
      SimulationPerformanceCatalogue_IncomingInventory: "Inventario Entrante",
      SimulationPerformanceCatalogue_StationOne: "Estación 1",
      SimulationPerformanceCatalogue_ManualLaborQueue: "Cola de Estación Manual",
      SimulationPerformanceCatalogue_ManualStation: "Estación Manual",
      SimulationPerformanceCatalogue_LaborUtilization: "Utilización de Mano de Obra",
      SimulationPerformanceCatalogue_ProductionProductB: "Producción - Krisps",
      SimulationPerformanceCatalogue_NetworkA: "Red Augusta",
      SimulationPerformanceCatalogue_IncomingInventoryProductAWarehouseAlphaFromLocalSupplier:
        "Almacén Aurora - Inventario Entrante",
      SimulationPerformanceCatalogue_IncomingInventoryProductAWarehouseBetaFromLocalSupplier:
        "Almacén Barcelona - Inventario Entrante",
      SimulationPerformanceCatalogue_IncomingInventoryProductAWarehouseGammaFromLocalSupplier:
        "Almacén Geneva - Inventario Entrante",
      SimulationPerformanceCatalogue_WarehouseAProductA: "Almacén Aurora - Nivel de Ricely",
      SimulationPerformanceCatalogue_WarehouseBProductA: "Almacén Barcelona - Nivel de Ricely",
      SimulationPerformanceCatalogue_WarehouseCProductA: "Almacén Geneva - Nivel de Ricely",
      SimulationPerformanceCatalogue_RegionAProductADemand: "Almacén Aurora - Demanda",
      SimulationPerformanceCatalogue_RegionBProductADemand: "Almacén Barcelona - Demanda",
      SimulationPerformanceCatalogue_RegionCProductADemand: "Almacén Geneva - Demanda",
      SimulationPerformanceCatalogue_RegionAProductABacklog: "Almacén Aurora - Órdenes Pendientes",
      SimulationPerformanceCatalogue_RegionBProductABacklog: "Almacén Barcelona - Órdenes Pendientes",
      SimulationPerformanceCatalogue_RegionCProductABacklog: "Almacén Geneva - Órdenes Pendientes",
      SimulationPerformanceCatalogue_NetworkB: "Red Buenavista",
      SimulationPerformanceCatalogue_IncomingInventoryProductBWarehouseAlphaFromLocalSupplier:
        "Almacén Alameda - Inventario Entrante",
      SimulationPerformanceCatalogue_IncomingInventoryProductBWarehouseBetaFromLocalSupplier:
        "Almacén Belmont - Inventario Entrante",
      SimulationPerformanceCatalogue_IncomingInventoryProductBWarehouseGammaFromLocalSupplier:
        "Almacén Grant - Inventario Entrante",
      SimulationPerformanceCatalogue_WarehouseAProductB: "Almacén Alameda - Nivel de Krisps",
      SimulationPerformanceCatalogue_WarehouseBProductB: "Almacén Belmont - Nivel de Krisps",
      SimulationPerformanceCatalogue_WarehouseCProductB: "Almacén Grant - Nivel de Krisps",
      SimulationPerformanceCatalogue_RegionAProductBDemand: "Almacén Alameda - Demanda",
      SimulationPerformanceCatalogue_RegionBProductBDemand: "Almacén Belmont - Demanda",
      SimulationPerformanceCatalogue_RegionCProductBDemand: "Almacén Grant - Demanda",
      SimulationPerformanceCatalogue_Finance: "Finanzas",
      SimulationPerformanceCatalogue_SupplyAndAssembly: "Suministro y Montaje",
    },
    SupplyChainDetailScreen: {
      simulationDetails: "Detalles de la Simulación",
      GetSimulationDetailsController_CantUseAPI:
        "No tiene permiso para utilizar este API, por favor contáctenos al correo support@processimlabs.com",
      GetSimulationDetailsController_SimulatinNotFound:
        "No pudimos encontrar esta simulación. Intente nuevamente. Si esto no funciona, por favor contáctenos al correo support@processimlabs.com",
    },
    SupplyChainJoinSimulation: {
      joinSimulationSuccessful: "Te has unido satisfactoriamente a la simulación.",
      JoinSimulationController_SimulationNotFound:
        "¡Oh no!, parece que la simulación a la que te has intentado unir no existe. Por favor confirma con tu profesor que has utilizado el link adecuado.",
      failedJoinSimulation: "No se ha podido unir a la simulación de Supply Grain.",
      JoinSimulationController_AlreadyJoined: "Ya te has unido a esta simulación",
      JoinSimulationController_MissingPaymentSummary:
        "Parece que la simulación a la que intentas unirte no ha sido pagada, verifica el pago con tu profesor",
      JoinSimulationController_ExcededLicenses:
        "¡Oh no!, parece que no hay una licencia disponible pagada para que puedas unirte a la simulación, por favor verifica el pago con tu profesor.",
    },
    SupplyChainModifyModal: {
      ModifySimulation: "Modificar Simulación",
      close: "Cerrar",
      accept: "Aplicar",
      Success: "Se ha modificado la simulación correctamente.",
    },
    SupplyChainPurchased: {
      advancePeriod: "La simulación avanzará cada:",
      GameModeTitle: "Seleccione un modo de Juego",
      SimulationStartInTheFuture: "Seleccione una fecha de inicio al menos una hora en el futuro",
      SimulationMinDurationError: "La simulación debe durar en ejecución como mínimo {{hours}} horas",
      SimulationMaxDurationError: "Las simulación debe durar en ejecución como máximo {{hours}} horas",
      TimeBetweenAdvancesOneHour: "1 hora",
      TimeBetweenAdvancesTwoHours: "2 horas",
      AdvancedOptions: "Opciones Avanzadas",
    },
    SupplyChainRunAgain: {
      CreateNextRunController_SimulationNotFound:
        "Ha habido un error creando la simulación, por favor inténtelo de nuevo. En caso de que esto no funcione contactenos, utilizando la función de chat o escribiendonos un correo a support@processimlabs.com.",
      CreateNextRunController_ErrorInitializingSimulation:
        "Ha habido un error inicializando la simulación, por favor inténtelo de nuevo. En caso de que esto no funcione, contactenos utilizando la función de chat o escribiendonos un correo a support@processimlabs.com.",
      scrambleTeamMembers: "Aleatorizar equipos: ",
      createNextRunSuccess:
        "La nueva simulación se ha configurado correctamente. Podrá acceder toda la información de esta en la página de inicio.",
      Success: "¡Éxito!",
    },
    SupplyChainShareModal: {
      ShareJoinLInk: "Comparte este enlace con tus estudiantes para que puedan unirse a tu simulación.",
    },
    SupplyChainStandings: {
      Team: "Equipo",
      Total: "Efectivo Total",
      SupplyAndAssembly: "Suministro y Montaje",
      Production: "Producción",
      NetworkA: "Red Augusta",
      NetworkB: "Red Buenavista",
      All: "Todos",
      EmptyStandings:
        "Disculpa, no hemos podido encontrar posiciones para tu simulación. Deberías poder verlas luego de inicializar tu simulación. Por favor vuelve luego.",
      "Team Member": "Miembro",
      Email: "Correo",
      Role: "Rol",
    },
    RunAgainScreen: {
      addedSuccessfully:
        "Actualmente estamos copiando a los estudiantes y equipos de la simulación anterior a esta. Este proceso puede tomar entre 5 y 10 minutos. Usted y sus estudiantes serán notificados por correo electrónico cuando haya terminado.",
      "Account not found.":
        "Cuenta no encontrada. Intente nuevamente o contáctenos en: support@processimlabs.com para obtener asistencia.",
      "Simulation not found.":
        "Simulación no encontrada. Intente nuevamente o contáctenos en: support@processimlabs.com para obtener asistencia.",
      "Your next run is being created, we will notify you when it's finished.":
        "Hemos recibido tu solicitud para la segunda ejecución. Podrás ver más detalles en tu pantalla de inicio en algunos minutos.",
      "Total student interaction can't be less than 100 days":
        "La interacción total del alumno no puede ser inferior a 100 días.",
      "Total student interaction can't be more than 300 days":
        "La interacción total del estudiante no puede ser más de 300 días.",
    },
    TutorialsScreen: {
      loadingTutorials: "Cargando vídeos, por favor espere ...",
      noVideosList: "Actualmente no hay listas de vídeos disponible.",
      Simunation: "Simunation",
      MedicaScientific: "Medica Scientific",
      errorFetchVideos:
        "Se ha producido un error al buscar sus vídeos, verifique su conexión a Internet y vuelva a cargar la página.",
    },
  },
};

export default resources;
