export const PREVIOUS = "previous";
export const NEXT = "next";
export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const HOUR_MERIDIEM = [
  { key: "am", value: "am" },
  { key: "pm", value: "pm" },
];
export const HOUR_NUMBERS = [];
for (let n = 12; n > 0; n--) {
  HOUR_NUMBERS.push({ key: n, value: n });
}
export const MEDICA_NAME = "Medica Scientific";
export const SIMUNATION_NAME = "Simunation";
export const NAVIGATION_PAGE = "page";
export const NAVIGATION_CONTINUE = "continue";
export const NAVIGATION_PAY = "pay";
export const NAVIGATION_SUBMIT = "submit";
export const NAVIGATION_LOGOUT = "logout";
export const QUESTIONNAIRE_OPTIONS = {
  STUDENTS: "Students",
  PROFESSOR: "Professor",
  BOOLEAN: "boolean",
};
export const SIX_HOURS_MILLISECONDS = 21600000;
export const SEVEN_DAYS_MILLISECONDS = 604800000;

export const ROLES = {
  PROFESSOR: "Professor",
  STUDENT: "Student",
};
export const SIMULATION_DEFAULT_START_DATE = "2030-01-01T00:00:00+00:00";
export const SIMULATION_DEFAULT_END_DATE = "2030-01-03T00:00:00+00:00";
export const SIMULATION_DEFAULT_TEMPLATE = "BTemplate";
export const ENV_DEVELOPMENT = "development";
export const ENV_PRODUCTION = "production";
export const DEV_LOCATION = "https://processimlabs-development.azurewebsites.net";
export const DELETE_COOKIE = "expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
export const START_SIMULATION = "startSimulation";
export const ADVANCE_SIMULATION = "advanceSimulation";
export const FINISH_SIMULATION = "finishSimulation";
export const AWAITING_INITIALIZATION = "AwaitingInitialization";
export const INITIALIZED = "Initialized";
export const RUNNING = "Running";
export const FINISHED = "Finished";
export const LANGUAGES_LIST = ["en", "es"];
export const PRODUCTS = {
  MEDICA: "medica",
  SIMUNATION: "simunation",
  CRISIS: "crisis",
};

export const CRISIS_NAME = "Bindz";
export const ABOGADOS_NAME = "Finch & Mason";
export const CREAM_BOW_NAME = "Cream Bow";
export const SUPPLY_CHAIN_NAME = "Supply Grain";

export const APP_VERSION_NUMBER = "2.1.3";
export const APP_DISTRIBUTION_NUMBER = "2";
